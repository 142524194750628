/*--------------------------------------------------------------------------------------*/
/*General-Css-Here
/*--------------------------------------------------------------------------------------*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--body-color);

  font-size: var(--bg-font);
  line-height: var(--body-line-height);
  font-weight: var(--font-weight-regular);
}
body::-webkit-scrollbar {
  display: none;
}

a {
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
} */

figure,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

footer,
header,
section {
  display: block;
}

footer:after,
footer:before,
header:after,
header:before,
section:after,
section:before {
  display: table;
  clear: both;
  content: "";
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    margin: auto;
    max-width: 1170px;
  }
}

a:focus {
  border: 0;
  outline: 0;
}

button:focus {
  border: 0;
  outline: 0;
}

select {
  appearance: none;
}

p,
span {
  margin: 0;
  padding: 0;
}

a:hover {
  text-decoration: none;
}

a {
  text-decoration: none;
  color: var(--color-black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-bold);
}

button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

.nav-link:focus,
.nav-link:hover {
  color: #fff;
}

.sub-title {
  font-size: 38px;
  color: #fd207a;
  font-family: violanteregular;
  padding-bottom: 15px;
  display: block;
}

.similer-title-head h2 {
  font-weight: 900;
  font-size: 44px;
  color: #292d32;
  text-transform: uppercase;
  position: relative;
}

.similer-title-head h2:after {
  background: url(../images/heading-element-light.png);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  content: "";
  width: 171px;
  height: 21px;
  margin: auto;
}

.space-similer {
  padding-top: 60px;
  padding-bottom: 60px;
}

a {
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}

.form-control::-webkit-input-placeholder {
  color: #707070;
}

.form-control:-ms-input-placeholder {
  color: #707070;
}

.form-control::placeholder {
  color: #707070;
}

select.form-control {
  height: 45px !important;
  color: #707070;
  background: url(../images/arrow-icon.png);
  background-position: right 10px center;
  background-repeat: no-repeat;
  font-size: 14px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f7f7f7;
  opacity: 1;
  border-color: #f7f7f7;
}

.with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*--------------------------------------------------------------------------------------*/
/*font-face
/*--------------------------------------------------------------------------------------*/

@font-face {
  font-family: "violanteregular";
  src: url("../fonts/violante-webfont.woff2") format("woff2"),
    url("../fonts/violante-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*--------------------------------------------------------------------------------------*/
/*Home-header
/*--------------------------------------------------------------------------------------*/
.navbar-inner-box {
  display: var(--flex-disply);
  align-items: var(--flex-align);
  justify-content: center;
  width: var(--full-width);
}

.navbar-collapse {
  background-color: #8d126e;
  position: fixed;
  top: 0;
  right: -227px;
  z-index: 9;
  width: 227px;
  height: 100vh;
  transition: 0.3s;
  padding-top: 110px;
}

.navbar-collapse.show {
  transition: 0.5s;
  right: 0;
  margin-left: 0;
}

.nav-R ul.navbar-nav {
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  flex-direction: column;
}

.nave-bar .hide-menu.show-menu li.nav-item a {
  color: #fff !important;
  padding: 10px 15px !important;
  border-bottom: 1px solid #dddddd38;
  font-size: 14px;
}

.navbar-toggler-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  border-radius: 0;
  background: #fff;
}

header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  border-bottom: var(--border);
  background: #ea2efb;
  background: -moz-linear-gradient(
    left,
    #ea2efb 0,
    #fd207a 10%,
    #8d126e 90%,
    #04005e 100%
  );
  background: -webkit-linear-gradient(
    left,
    #ea2efb 0,
    #fd207a 10%,
    #8d126e 90%,
    #04005e 100%
  );
  background: linear-gradient(
    to right,
    #ea2efb 0,
    #fd207a 10%,
    #8d126e 90%,
    #04005e 100%
  );
}

.nav-R {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
}

.topHeader.sticky {
  border-bottom: 0;
  -webkit-box-shadow: 0 0.9375rem 1.25rem -0.625rem rgb(0 0 0 / 20%);
  box-shadow: 0 0.9375rem 1.25rem -0.625rem rgb(0 0 0 / 20%);
  -webkit-animation: slideInDown 0.8s cubic-bezier(0.5, 1.5, 0.5, 1.5) 0s 1 both
    running;
  animation: slideInDown 0.8s cubic-bezier(0.5, 1.5, 0.5, 1.5) 0s 1 both running;
  z-index: 999;
  top: 0;
}

.padding-head {
  padding-top: 84px;
}

.nav-L {
  display: var(--flex-disply);
  align-items: var(--flex-align);
}

.nav-L .nav-link {
  font-size: 15px;
  color: var(--color-black);
  text-transform: var(--text-uppercase);
  padding: 0 15px !important;
  font-weight: var(--font-weight-mdm);
}

a.navbar-brand {
  margin: 0 !important;
  padding: 0;
}

.nav-link {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 7px 15px !important;
  text-align: center;
}

a.nav-link.active,
a.nav-link:hover {
  background: #fff;
  color: #292d32;
}

.navbar-nav li.nav-item {
  margin-bottom: 5px;
}

.sticky .navbar {
  padding: 10px 0;
}

.navbar-expand-lg .navbar-toggler {
  display: block;
}

button.navbar-toggler {
  position: absolute;
  right: 0;
  z-index: 9;
  justify-content: center;
  padding: 0;
  line-height: normal;
  bottom: 0;
  margin: auto;
  top: 0;
  border: 0;
}

span.navbar-toggler-icon i {
  color: #fff;
  font-size: 22px;
}

span.navbar-toggler-icon i {
  color: #8d126e;
  font-size: 30px;
}

/*--------------------------------------------------------------------------------------*/
/*Home-banner
/*--------------------------------------------------------------------------------------*/

.banner-main-box {
  background: url(../images/banner.jpg.png.jpg);
  height: 544px;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-main-box > figure img {
  width: var(--full-width);
}

.banner-top-inner {
  display: var(--flex-disply);
  height: 100%;
}

.banner-top-L {
  flex: 0 0 55%;
  display: var(--flex-disply);
  flex-direction: column;
  justify-content: var(--flex-justify);
  z-index: 9;
}

.banner-top-L h1 {
  font-size: 57px;
  line-height: 63px;
  color: #fff;
}

.banner-top-R figure img {
  z-index: 1;
  position: var(--position-rel);
}

.banner-top-L .banner-top-L-content {
  font-size: var(--text-font18);
  line-height: 32px;
  padding-top: var(--pt-10);
  padding-right: 25px;
  color: #fff;
  padding-bottom: 5px;
}

.banner-top-R {
  flex: 0 0 45%;
  text-align: var(--text-right);
  display: flex;
  align-items: end;
  justify-content: center;
}

.banner-top-L small {
  font-size: 18px;
  color: #fff;
  font-weight: 300;
  padding-bottom: 10px;
}

/*--------------------------------------------------------------------------------------*/
/*Home-how-works
/*--------------------------------------------------------------------------------------*/
.inner-tabs-link li a {
  display: flex;
  align-items: center;
  padding: 0 30px;
  border-right: 1px solid #b2b2b2;
  font-size: 20px;
  color: rgb(78 85 93 / 59%) !important;
  font-weight: 700;
}

.inner-tabs-link li:last-child a {
  border: 0;
}

.inner-tabs-link li span {
  margin-right: 15px;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  border-radius: 50%;
}

.inner-tabs-link li.active a {
  color: #fd207a !important;
}

.inner-tabs-link li.active a span {
  background: #fd207a;
}

.inner-tabs-link.join-chef-tabs li.active a span img {
  -webkit-filter: brightness(0) invert(1);
}

.inner-tabs-link.join-chef-tabs li.active a {
  color: #8d126e !important;
}

.inner-tabs-link.join-chef-tabs li.active a span {
  background: #8d126e;
}

.inner-tabs-link li.active a span img {
  -webkit-filter: brightness(0) invert(1);
}

.inner-tabs-link {
  padding-bottom: 32px;
}

.food-box-similer figure img {
  height: 204px;
  border-radius: 10px;
  width: 100%;
  object-fit: fill;
}

.food-box-similer figcaption {
  padding-top: 20px;
}

.food-box-similer figcaption h3 {
  font-size: 22px;
  color: #292d32;
  text-transform: uppercase;
  line-height: 26px;
  font-weight: 900;
  padding: 0 17px;
}

.food-box-similer figcaption p {
  font-size: 16px;
  color: #292d32;
  line-height: 26px;
}

.how-its-works-chef,
.how-its-works-customer {
  position: relative;
  overflow: hidden;
}

.join-customer:hover {
  color: #fd207a !important;
  transition: 0.3s;
}

.join-customer:hover span {
  background: #fd207a;
  transition: 0.3s;
}

.join-customer:hover span img {
  -webkit-filter: brightness(0) invert(1);
}

.join-chef:hover {
  color: #8d126e !important;
}

.join-chef:hover span {
  background: #8d126e;
  transition: 0.3s;
}

.join-chef:hover span img {
  -webkit-filter: brightness(0) invert(1);
}

/*--------------------------------------------------------------------------------------*/
/*Home-video
/*--------------------------------------------------------------------------------------*/
.wrapper-video {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 648px;
}

.wrapper-video:after {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ea2efb+0,ff207a+27,fd207a+27,04005e+100&0.34+0,0.34+100 */
  background: -moz-linear-gradient(
    left,
    rgba(234, 46, 251, 0.34) 0%,
    rgba(253, 32, 122, 0.34) 27%,
    rgba(4, 0, 94, 0.34) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(234, 46, 251, 0.34) 0%,
    rgba(253, 32, 122, 0.34) 27%,
    rgba(4, 0, 94, 0.34) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(234, 46, 251, 0.34) 0%,
    rgba(253, 32, 122, 0.34) 27%,
    rgba(4, 0, 94, 0.34) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#57ea2efb', endColorstr='#5704005e', GradientType=1);
  /* IE6-9 */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
}

.wrapper-video img {
  height: 100%;
  width: 100%;
}

.wrapper-video iframe {
  display: none;
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
}

.wrapper-video .play-btn {
  position: absolute;
  z-index: 666;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
}

.wrapper-video .play-btn:hover {
  cursor: pointer;
}

.wrapper-video .play-btn:focus {
  outline: 0;
}

/*--------------------------------------------------------------------------------------*/
/*Home-our-clients-sider
/*--------------------------------------------------------------------------------------*/
.our-clients.comm-space.space-similer {
  background: url(../images/clients-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.item-content figure img {
  width: 68px !important;
  height: 68px;
  margin: auto;
  border-radius: var(--border-radius);
}

.item-content {
  text-align: var(--text-center);
  padding: 40px 45px 40px;
  background: #fff;
  border-radius: 6px;
}

.item-content figure {
  margin: 30px 0 10px;
}

.our-users .owl-dots {
  margin-top: var(--mt-10);
}

.item-content h4 {
  font-size: var(--text-font22);
  color: var(--body-color);
  text-transform: uppercase;
  font-weight: 900;
}

.item-content p {
  font-size: 16px;
}

.our-users .owl-stage-outer {
  padding-top: 60px;
  padding-bottom: 30px;
}

.owl-dots {
  display: var(--flex-disply);
  align-items: var(--flex-align);
  justify-content: var(--flex-justify);
  margin-top: 40px;
}

.owl-dots button span {
  background: rgb(41 45 50 / 26%);
  display: block;
  width: 11px;
  height: 11px;
  border-radius: var(--border-radius);
}

.owl-dots button {
  margin: 0 5px;
}

.owl-dots button.owl-dot.active span {
  background: #292d32;
}

.app-store-btn:hover img {
  opacity: 0.8;
  cursor: pointer;
}

.play-btn-app a:hover img {
  opacity: 0.7;
}

.slider-box-content figure img {
  border-radius: var(--border-radius-20);
  box-shadow: 0 1px 20px 0 rgb(209 223 230 / 56%);
  border: 1px solid #dddddd59;
}

.slider-owl .owl-stage-outer {
  padding: 20px 0;
}

.slider-owl .item {
  border-radius: var(--border-radius-20);
}

/*--------------------------------------------------------------------------------------*/
/*Home-contact
/*--------------------------------------------------------------------------------------*/
.combine-group {
  justify-content: space-between;
  flex-wrap: wrap;
}

.combine-group .mb-3 {
  width: 49%;
}

.textarea-box textarea {
  height: 161px;
  resize: none;
  font-size: 14px;
}

input.form-control {
  height: 45px;
  font-size: 14px;
  border: 1px solid #d1d7db;
  border-radius: 6px;
  line-height: 22px;
  color: #000;
  font-weight: 400;
}

.form-control::-webkit-input-placeholder {
  color: #000;
}

.form-control:-ms-input-placeholder {
  color: #000;
}

.form-control::placeholder {
  color: #000;
}

.btn {
  border-radius: 6px !important;
  font-size: 18px;
  background: #ddd;
  text-transform: capitalize;
  color: #fff;
  height: 60px;
  font-weight: 700;
  width: 210px;
  border: 0;
  line-height: 36px;
}

.pink-btn {
  background: #fd207a;
}

.contact-us-home {
  position: relative;
  overflow: hidden;
}

.purple-btn {
  background: #8d126e;
}

.password-box {
  position: relative;
}

.eyeBtn {
  position: absolute;
  right: 10px;
  top: 11px;
}

.eyeBtn i {
  font-size: 18px;
}

.password-box input.form-control {
  padding-right: 40px;
}

.errorMessage {
  font-size: 14px;
  color: red;
  text-align: left;
}

a#close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 26px;
  color: #fff;
  font-weight: 300;
}

.navbar-collapse:after {
  background: rgb(255 255 255 / 18%);
  position: absolute;
  right: -25%;
  bottom: -77px;
  content: "";
  width: 163px;
  height: 159px;
  border-radius: 50%;
}

.navbar-collapse:before {
  background: rgb(255 255 255 / 18%);
  position: absolute;
  right: 60px;
  bottom: 73px;
  content: "";
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.google-btn a:hover {
  opacity: 0.7;
  cursor: pointer;
}

/*--------------------------------------------------------------------------------------*/
/*Home-footer
/*--------------------------------------------------------------------------------------*/
footer {
  position: var(--position-rel);
  overflow: hidden;
}

footer .container {
  z-index: 11;
  position: relative;
}

.footer-content .footer-logo {
  margin-bottom: var(--mb-15);
  display: inline-block;
}

.footer-menu {
  padding: 50px 0 20px 0;
  background: rgb(78 85 93 / 11%);
  z-index: 1;
  position: relative;
}

.footer-content h3 {
  font-size: var(--text-font22);
  padding-bottom: var(--mb-15);
  color: #292d32;
  font-weight: 900;
  text-transform: uppercase;
}

.footer-bottom {
  padding: 15px 0;
  background: #131313;
}

.footer-social {
  display: var(--flex-disply);
  align-items: var(--flex-align);
}

.footer-social li {
  padding-right: 5px;
}

.footer-content li a {
  font-size: 16px;
  color: #292d32;
  text-decoration: none;
  font-weight: 400;
}

.footer-social li a {
  width: 46px;
  height: 46px;
  display: var(--flex-disply);
  align-items: var(--flex-align);
  justify-content: var(--flex-justify);
  font-size: 24;
  text-align: var(--text-center);
  border-radius: var(--border-radius);
  color: #fd207a;
  border: 1px solid #fd207a;
}

.footer-social li a:hover {
  background: var(--bg-white);
  border-color: #fff;
}

.footer-social li a:hover i {
  color: var(--common-color);
}

.footer-content li {
  padding: 5px 0;
  margin-right: var(--mr-10);
}

.footer-content li a:hover {
  cursor: pointer;
  color: #fd207a;
}

.bottom-footer {
  background: rgb(236 236 237);
  text-align: var(--text-center);
  padding: 10px 0 30px;
}

.bottom-footer p {
  font-size: 14px;
  font-weight: 600;
  color: rgb(41 45 50 / 56%);
}

.copyright-text p {
  font-size: var(--font-14);
  color: #dbdbdb;
  text-align: var(--text-center);
  margin-bottom: 0;
}

/*--------------------------------------------------------------------------------------*/
/*About-page
/*--------------------------------------------------------------------------------------*/
.inner-banner {
  position: relative;
}

.inner-banner:after {
  background: rgb(41 45 50 / 56%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
}

.banner-inner-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-inner-content h4 {
  font-size: 38px;
  color: #fff;
  z-index: 9;
  font-weight: 400;
}

.about-steps-box figure {
  background: #292d32;
  height: 131px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-steps-box figcaption {
  background: #8d126e;
  height: 131px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.about-steps-box figcaption h4 {
  color: #fff;
  font-weight: 900;
}

.about-steps-box {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.about-steps-box:after {
  border: 1px solid rgb(255 255 255 / 16%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
  width: 85%;
  margin: auto;
  height: 90%;
  border-radius: 6px;
}

.pinq-text {
  font-size: 24px;
  color: #8d126e;
  line-height: 39px;
  margin-bottom: 20px;
}

.about-us-description {
  background: url(../images/about-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.about-bottom-L figure img {
  height: 377px;
  width: 527;
}

.about-bottom-R h3 {
  font-size: 34px;
  color: #292d32;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 42px;
  margin-bottom: 15px;
}

.about-bottom-R ul {
  list-style: disc;
  padding-left: 15px;
}

.about-us-description {
  position: relative;
  overflow: hidden;
}

/*--------------------------------------------------------------------------------------*/
/*Contact-page
/*--------------------------------------------------------------------------------------*/

.contact-info-list {
  display: flex;
  justify-content: center;
}

.contact-info-list li {
  background: rgb(112 112 112 / 6%);
  text-align: center;
  width: 50%;
  margin: 0 15px;
  padding: 40px 20px 35px;
  border-radius: 6px;
}

.contact-info-list li figure {
  background: rgb(141 18 110 / 7%);
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 26px;
  color: #8d126e;
  margin: auto;
  margin-bottom: 20px;
}

.contact-info-list figcaption h4 {
  font-size: 21px;
  color: #292d32;
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: 15px;
}

.contact-info-list {
  display: flex;
  justify-content: center;
  width: 800px;
  margin: auto;
}

.contact-info-list figcaption a {
  font-size: 16px;
  font-weight: 600;
}

.contact-info-list li:hover {
  box-shadow: 0 1px 8px rgb(0 0 0 / 13%);
  cursor: pointer;
  transition: 0.3s;
}

.contact-info-list li:hover figcaption a {
  color: #fd207a;
}

.contact-info-list li:hover figure {
  transform: scale(1.1);
}

/*--------------------------------------------------------------------------------------*/
/*faq-page
/*--------------------------------------------------------------------------------------*/

.faq-inner .accordion-button {
  background-color: transparent;
  font-size: 16px;
  padding: 21px 20px;
  font-weight: 700;
  color: #292d32;
}

.faq-inner .accordion-button:not(.collapsed) {
  padding-bottom: 0;
  color: #fd207a;
  background-color: transparent;
  box-shadow: none;
  border-radius: 6px !important;
}

.faq-inner .accordion-item {
  margin-bottom: 18px;
  border-radius: 6px !important;
  overflow: hidden;
  border: 0 solid;
  background: rgb(112 112 112 / 6%);
}

.faq-inner .accordion-item:not(:first-of-type) {
  border-top: 0 solid var(--body-color);
}

.faq-inner .accordion-item .accordion-button::after {
  background-image: url(../images/plus-icon.png);
  background-position: center;
}

.faq-inner .accordion-button:not(.collapsed)::after {
  background-image: url(../images/minus-icon.png);
  transform: none;
}

.faq-inner .accordion-button:focus {
  outline: 0;
  box-shadow: none;
}

.faq-inner .nav-tabs .nav-link {
  margin-bottom: 0;
  background: 0 0;
  border: 0;
  border-top-left-radius: initial;
  border-top-right-radius: inherit;
  display: flex;
  align-items: center;
  color: rgb(78 85 93 / 59%) !important;
  font-size: 20px;
  color: rgb(78 85 93 / 59%) !important;
  font-weight: 700;
}

.faq-inner .nav-link.chef-active.active {
  color: #8d126e !important;
  margin-bottom: 0;
}

.faq-inner .nav-link.chef-active.active span {
  background: #8d126e;
}

.faq-inner .nav-link.active span img {
  -webkit-filter: brightness(0) invert(1);
}

.faq-inner .nav-item .nav-link {
  border-right: 1px solid #b2b2b2;
}

.faq-inner .nav-item:last-child .nav-link {
  border-right: 0px solid #b2b2b2;
}

.faq-inner .nav-tabs .nav-link:focus,
.faq-inner .nav-tabs .nav-link:hover {
  border-color: #b2b2b2;
  isolation: isolate;
  border-radius: initial;
}

.faq-inner .nav-tabs {
  border-bottom: 0;
}

.faq-inner .nav-link.customer-active.active {
  color: #fd207a !important;
  margin-bottom: 0;
}

.faq-inner .nav-link.customer-active.active span {
  background: #fd207a;
}

.faq-inner-purple .accordion-button:not(.collapsed) {
  color: #8d126e;
}

/*--------------------------------------------------------------------------------------*/
/*terms-policy
/*--------------------------------------------------------------------------------------*/

.terms-policy-content-box h4 {
  font-size: 22px;
  color: #292d32;
  font-weight: 900;
  margin-bottom: 10px;
}

.terms-policy-content-box {
  margin-bottom: 30px;
}

.terms-policy-content-box p {
  margin-bottom: 15px;
}

.terms-policy-content-box ul {
  list-style-type: disc;
}

.catering-content-box ul {
  list-style-type: disc;
}

.terms-policy-content-box a {
  color: #0d6efd;
}

.terms-policy-content-box:last-child {
  margin-bottom: 0;
}

.inner-heading h2 {
  font-size: 34px;
  color: #292d32;
  text-transform: uppercase;
  font-weight: 900;
}

.how-its-works-chef button.btn.purple-btn.banner-btn:hover {
  color: #fff;
}

/*--------------------------------------------------------------------------------------*/
/*Login-css
/*--------------------------------------------------------------------------------------*/

.min-height {
  height: 48px;
}

.form-head h2 {
  font-size: 32px;
  color: #292d32;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-weight: 900;
}

.form-head p {
  font-size: 16px;
  color: #4e555d;
}

.form-label {
  font-size: 16px;
  color: #292d32;
  font-weight: 700;
  display: inline-flex;
  line-height: normal;
  position: relative;
}

.form-head {
  margin-bottom: 45px;
}

.forget-password {
  font-size: 16px;
  color: #8d126e;
  font-weight: 700;
  padding-top: 10px;
  background: transparent;
  border: 0;
  text-align: right;
  display: block;
  width: 100%;
}

/*--------------------------------------------------------------------------------------*/
/*modal-css
/*--------------------------------------------------------------------------------------*/

.mar-t {
  margin-top: -115px;
}

.modal-dialog {
  max-width: 542px;
}

.modal-content {
  border-radius: 22px;
  padding: 60px 80px;
}

.modal-title {
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 15px 0 10px;
}

.modal-head p {
  font-size: 16px;
  color: #4e555d;
  letter-spacing: 1px;
}

.modal-body.text-center {
  padding: 0;
}

.close-btn {
  background: 0 0;
  border: 0;
  text-align: right;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 25px;
  color: #707070;
}

/*--------------------------------------------------------------------------------------*/
/*coustom-checkbox
/*--------------------------------------------------------------------------------------*/

.coustom-checkbox p {
  font-size: 13px;
  color: #292d32;
  font-weight: 700;
}

.coustom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.coustom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.coustom-checkbox .checkmark {
  border: 1px solid #8d126e;
  position: absolute;
  top: 7px;
  left: 0;
  height:14px;
  width: 14px;
  border-radius: 3px;
}

.coustom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.coustom-checkbox input:checked ~ .checkmark {
  background: #8d126e;
}

.coustom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.coustom-checkbox .checkmark:after {
  left: 0;
  top: -2px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 0;
  bottom: 0;
  margin: auto;
}

.coustom-checkbox p a {
  color: #8d126e;
  text-decoration: underline;
}

/*--------------------------------------------------------------------------------------*/
/*sidebar-signup
/*--------------------------------------------------------------------------------------*/

.sidebar {
  width: 343px;
  position: absolute;
  top: 230px;
  left: 0;
  background: #8d126e;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 9;
}

.sidebar .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.sidebar .accordion-item {
  background-color: transparent;
  border: 0;
  margin-bottom: 20px;
}

.sidebar h3 {
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding: 10px 10px;
  text-transform: uppercase;
  background: #292d32;
  font-weight: 600;
}

.sidebar .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 15px;
}

.sidebar .accordion-button {
  background-color: transparent;
  color: #fff;
  padding: 0;
}

.sidebar .coustom-checkbox p {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.sidebar .coustom-checkbox .checkmark {
  border: 1px solid #fff;
}

.sidebar .coustom-checkbox input:checked ~ .checkmark {
  background: #fff;
}

.sidebar .coustom-checkbox .checkmark:after {
  border: solid #8d126e;
  border-width: 0 2px 2px 0;
}

.sidebar .accordion {
  padding: 20px 20px 10px;
}

.sidebar .accordion-body label.coustom-checkbox p {
  font-size: 14px;
  font-weight: 400;
}

.sidebar .accordion-body label.coustom-checkbox {
  margin-bottom: 0;
}

.sidebar .accordion-body {
  padding: 10px 20px 0;
}

.sidebar .coustom-checkbox {
  margin-bottom: 0;
}

.sidebar .accordion-button::after {
  -webkit-filter: brightness(0) invert(1);
}

.sidebar .accordion-button::after {
  background-size: 16px;
}

.sidebar .sidebar-bottom-title {
  font-size: 20px;
  color: #fff;
  text-align: center;
  padding-bottom: 25px;
}

.space-2 .modal-content {
  padding: 40px 50px;
}

/*--------------------------------------------------------------------------------------*/
/*profile-signup
/*--------------------------------------------------------------------------------------*/

.avatar-upload {
  position: relative;
  width: 128px;
  margin: auto;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-preview {
  width: 128px;
  height: 128px;
  position: relative;
  border-radius: 0;
  border: 1px solid #8d126e;
  margin-bottom: 30px;
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #8d126e;
  cursor: pointer;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  position: absolute;
  bottom: 0;
  top: auto;
  right: 4px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: #fff;
}

.img-upload-icon i {
  font-size: 16px;
  font-weight: 300;
}

.info-tag {
  position: absolute;
  right: -23px;
  top: 0;
}

.info-tag i {
  font-size: 19px;
  color: #707070;
}

/*--------------------------------------------------------------------------------------*/
/*signup-file-upload
/*--------------------------------------------------------------------------------------*/

.file {
  position: relative;
  width: 110px;
  height: 110px;
}

.file > input[type="file"] {
  display: none;
}

.file .file-label {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-radius: 6px;
  background: #8d126e;
  color: #fff;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  text-transform: capitalize;
  margin-bottom: 0;
  width: 110px;
  position: relative;
  overflow: hidden;
}

.file .file-label input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0;
}

.file--upload label img {
  -webkit-filter: brightness(0) invert(1);
  margin-bottom: 3px;
}

.uploding-list {
  display: flex;
  flex-wrap: wrap;
}

.uploading-group {
  display: flex;
  flex-wrap: wrap;
}

.uploding-list li span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 4px;
}

.uploding-list li {
  width: 110px;
  height: 110px;
  margin-right: 10px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

/*.uploding-list li:after{display:block;position:absolute;content:"";left:0;right:0;top:0;bottom:0;background:-moz-linear-gradient(top,rgba(0,0,0,.62) 0,rgba(0,0,0,0) 62%,rgba(0,0,0,0) 100%);background:-webkit-linear-gradient(top,rgba(0,0,0,.62) 0,rgba(0,0,0,0) 62%,rgba(0,0,0,0) 100%);background:linear-gradient(to bottom,rgba(0,0,0,.62) 0,rgba(0,0,0,0) 62%,rgba(0,0,0,0) 100%)}*/
.uploading-group .file .file-label i {
  font-size: 28px;
  font-weight: 300;
  padding-bottom: 5px;
}

.file.file--upload span {
  font-size: 14px;
}

.uploding-list .close-btn {
  position: absolute;
  right: 6px;
  top: 6px;
  background: #fff;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  border-radius: 50%;
  font-size: 10px;
  z-index: 1;
  cursor: pointer;
  font-weight: 700;
}

.upload-document .uploding-list li {
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.upload-document .uploding-list li span {
  text-align: center;
}

.upload-document .uploding-list li span b {
  display: block;
  font-size: 14px;
  color: #292d32;
  font-weight: 500;
}

.upload-document .uploding-list li span i {
  font-size: 20px;
  font-weight: 500;
}

.upload-document .close-btn {
  background: #000;
  color: #fff;
}

/*--------------------------------------------------------------------------------------*/
/*tags-input
/*--------------------------------------------------------------------------------------*/

.tags-input-wrapper {
  min-height: 45px;
  font-size: 14px;
  border: 1px solid #d1d7db;
  border-radius: 6px;
  line-height: 22px;
  color: #000;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 2px 10px;
  height: auto;
  flex-wrap: wrap;
}

.tags-input-wrapper input {
  border: none;
  background: 0 0;
  outline: 0;
  width: 140px;
  margin-left: 8px;
  margin-bottom: 0;
}

.tags-input-wrapper .tag {
  background-color: #8d126e;
  color: #fff;
  border-radius: 6px;
  padding: 4px 12px;
  margin: 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.tags-input-wrapper .tag a {
  margin: 0;
  display: inline-block;
  cursor: pointer;
  padding-left: 6px;
  font-size: 20px;
  font-weight: 300;
}

/*--------------------------------------------------------------------------------------*/
/*radio-coustom
/*--------------------------------------------------------------------------------------*/

.radio-coustom-design {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-coustom-design p {
  font-size: 16px;
  font-weight: 400;
}

.radio-coustom-design input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.radio-coustom-design .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 2px solid #707070;
}

.radio-coustom-design input:checked ~ .checkmark {
  background-color: #fff;
  border: 2px solid #8d126e;
}

.radio-coustom-design .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-coustom-design input:checked ~ .checkmark:after {
  display: block;
}

.radio-coustom-design .checkmark:after {
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #8d126e;
  right: 0;
  bottom: 0;
  margin: auto;
}

.sub-title-inner {
  display: block;
  font-size: 14px;
  padding-bottom: 10px;
}

.purple-btn.trans-btn {
  background: 0 0;
  border: 1px solid #8d126e;
  color: #8d126e;
}

.red-btn.trans-btn {
  background: 0 0;
  border: 1px solid red;
  color: red;
}

.Price-suggestion b {
  display: block;
  font-size: 16px;
  font-weight: 800;
  border-left: 2px solid #8d126e;
  line-height: normal;
  padding-left: 10px;
  margin-bottom: 8px;
}

.Price-suggestion {
  padding-top: 10px;
  display: block;
}

.Price-suggestion {
  font-size: 16px;
}

.Price-suggestion b i {
  font-style: inherit;
  color: #8d126e;
}

.action-btn a {
  background: #8d126e;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff !important;
  font-size: 20px;
  margin-left: 15px;
}

.action-btn {
  margin-left: 10px;
}

.dietary-list {
  display: flex;
  flex-wrap: wrap;
}

.dietary-list li {
  margin-right: 18px;
}

.dietary-list li .coustom-checkbox p {
  font-size: 15px;
}

.s-btn {
  width: 169px;
  font-size: 15px;
}

.inner-text-content {
  display: block;
  font-size: 15px;
}

.inner-title {
  font-size: 15px;
  padding-bottom: 15px;
  font-weight: 800;
  text-transform: uppercase;
}

div#tutorials .modal-dialog {
  max-width: 680px;
}

.welcome-box h3 {
  padding-top: 20px;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 23px;
}

.welcome-box p {
  font-size: 16px;
  color: #4e555d;
  padding-top: 10px;
  line-height: 24px;
}

.modal-body {
  padding: 0;
}

.modal-body.slider-box-content h4 {
  font-size: 17px;
  color: #292d32;
  padding-top: 15px;
  padding-bottom: 10px;
  font-weight: 800;
  text-transform: uppercase;
}

.modal-body.slider-box-content p {
  font-size: 16px;
  color: #4e555d;
}

.slider-box-content .carousel-item img {
  border-radius: 6px;
  overflow: hidden;
  height: 270px;
  object-fit: cover;
}

/*--------------------------------------------------------------------------------------*/
/*inner-header
/*--------------------------------------------------------------------------------------*/
.inner-header {
  background: #fff;
  box-shadow: 0 5px 4px rgb(0 0 0 / 11%);
  padding: 20px 25px;
}

.inner-header-box {
  justify-content: space-between;
  align-items: center;
}

.sidebar-L {
  background: #8d126e;
  width: 227px;
  position: fixed;
  left: 0;
  right: 0;
  top: 80px;
  bottom: 0;
  z-index: 9;
  height: 100%;
  overflow: auto;
  padding-top: 32px;
  padding-bottom: 50px;
  height: calc(100vh - 80px);
}

.sidebar-L li a {
  padding: 7px 40px;
  width: 100%;
  color: #fff !important;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.sidebar-L li a i {
  padding-right: 15px;
  font-size: 24px;
}

.sidebar-L ul li {
  margin-bottom: 10px;
}

.sidebar-L ul li a:hover,
.sidebar-L ul li a.active {
  background: #fff;
  color: #000 !important;
}

.drop-down-profile .dropdown-toggle {
  background: 0 0;
  padding: 0;
  height: auto;
  display: flex;
  align-items: center;
}

.profile-title-text {
  color: #292d32;
}

.dashboard-top-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  padding: 20px 20px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 11%);
}

.dash-R {
  padding-left: 252px;
  padding-right: 20px;
  padding-top: 20px;
}

.purple-bg {
  background: #8d126e !important;
}

.dashboard-top-box-L b {
  display: block;
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  padding-bottom: 5px;
}

.dashboard-top-box-L span {
  font-size: 15px;
  color: #fff;
}

.dashboard-top-box-R span {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  font-size: 24px;
}

.pink-bg {
  background: #fd207a !important;
}

.dash-head h3 {
  font-size: 32px;
  color: #292d32;
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: 20px;
}

.purple-bg .dashboard-top-box-R span i {
  color: #8d126e;
}

.pink-bg .dashboard-top-box-R span i {
  color: #fd207a;
}

.dash-accordion .accordion-body {
  padding: 0;
  padding: 5px 20px 30px;
}

.dash-accordion .accordion-item {
  box-shadow: 0 1px 4px rgb(0 0 0 / 11%);
  border-radius: 6px;
  margin-bottom: 25px;
  padding: 20px 20px;
}

.dash-accordion .accordion-button {
  background-color: transparent;
  display: block;
}

.dash-accordion .accordion-button:not(.collapsed) {
  color: initial;
  background-color: #fff;
  box-shadow: none;
  border-radius: 0;

  padding: 0;
}

.dash-accordion .accordion-button::after {
  position: absolute;
  right: 20px;
  top: 20px;
  background-image: url(../images/arrow-drop.png) !important;
  width: 13px;
  height: 8px;
  background-size: contain;
}

.dish-head small {
  font-size: 14px;
  color: #292d32;
  font-weight: 600;
  display: block;
}

.dish-head h5 {
  font-size: 15px;
  color: #292d32;
  /* padding-top: 10px */
}

.dish-head {
  padding-bottom: 15px;
}

.Dishes-list li {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.Dishes-list-L img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.Dishes-list-L {
  margin-right: 15px;
}

.Dishes-list-R h4 {
  font-size: 16px;
  color: #292d32;
  padding-bottom: 7px;
}

.Dishes-list-R span {
  font-size: 14px;
  color: #292d32;
  padding-bottom: 0 !important;
  padding-right: 15px;

  font-weight: 400;
}

.Dishes-list {
  display: flex;
  padding-bottom: 15px;
}

.without-tabs ul.Dishes-list {
  padding-bottom: 0;
}

.Delivery-content {
  display: flex;
  flex-wrap: wrap;
}

.Delivery-content li {
  padding-right: 20px;
  padding-bottom: 5px;
}

.innner-title-dash {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 900;
  padding: 0 0 20px;
}

.profile-title-text img {
  margin-right: 14px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.profile-title-text {
  font-size: 14px;
  font-weight: 600;
}

.dropdown-menu.show {
  display: block;
  right: 0 !important;
  left: auto !important;
  min-width: 205px;
  border: 1px solid #dddddd87;
}

.inner-header-R .dropdown-toggle::after {
  color: rgb(0 0 0 / 70%);
  margin-left: 10px;
}

.tool-tip-box i {
  font-size: 20px;
  padding-left: 7px;
  color: #707070;
  font-weight: 300;
}

.con-tooltip {
  /* transition: all .3s ease-in-out; */
  cursor: default;
  position: absolute;
  right: -25px;
  /* z-index: 99; */
}

.tooltip {
  display: none;
  opacity: 0.4;
  width: auto;
  position: absolute;
  bottom:43px;
  left: 7px;
  border-radius: 5px;
  /* transform: translateY(9px); */
  transition: all 0.3s ease-in-out;
  min-width: 400px;
}
.tooltip-under{
  display: none;
  z-index: 1;
  opacity: 0.4;
  width: auto;
  position: absolute;
  bottom: 26px;
  /* left: 10px; */
  border-radius: 5px;
  /* transform: translateY(9px); */
  transition: all 0.3s ease-in-out;
  min-width: 400px;
  right: 0;
}

.tooltip p {
  max-height: 120px;
  overflow: auto;
  padding: 15px 15px;
  background: #c7c7c7;
  color: #444141;
}

.tooltip::after {
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 6.5px 0 6.5px;
  border-color: #c7c7c7 transparent transparent transparent;
  position: absolute;
  right: auto;
  bottom: -7px;
  left: 3px;
  margin: auto;
}

.con-tooltip:hover .tooltip {
  display: block;
  transform: translateY(-10px);
  opacity: 1;
  transition: 0.5s linear;
}

.update-con-tooltip:hover .tooltip {
  display: block;
  transform: translateY(230px);
  opacity: 1;
  transition: 0.5s linear;
}

/* .top:hover {
    transform: translateY(-6px)
} */

@keyframes odsoky {
  0% {
    transform: translateY(6px);
  }

  100% {
    transform: translateY(1px);
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  -webkit-filter: invert(1) contrast(900%);
}

.filter-head {
  margin-bottom: 20px;
}

.filter-head {
  display: flex;
  overflow: auto;
}

.filter-text-button span {
  font-size: 16px;
}

.filter-button.blank-data {
  background: #d2c9c9;
}

.filter-button {
  font-size: 15px;
  border-radius: 6px;
  text-align: center;
  color: #707070 !important;
  background: #f7f7f7;
  padding: 7px 18px;
  display: inline-block;
  white-space: nowrap;
  margin-right: 15px;
}

.gallery-title:after {
  content: "";
  position: absolute;
  width: 7.5%;
  left: 46.5%;
  height: 45px;
  border-bottom: 1px solid teal;
}

.active.filter-button {
  background: #8d126e;
  color: #fff !important;
}

.port-image {
  width: 100%;
}

.gallery_product {
  margin-bottom: 30px;
}

.add-tabs-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8d126e;
  border-radius: 50%;
  color: #fff;
  font-size: 23px;
  font-weight: 300;
  margin-left: 10px;
}

.gallery_product {
  position: relative;
}

.gallery_product .coustom-checkbox {
  margin-bottom: 0;
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1;
}

.gallery_product {
  margin-bottom: 30px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 11%);
  border-radius: 6px;
}

.gallery_product-top img {
  width: 100%;
  height: 241px;
}

.gallery_product-content {
  padding: 15px 20px;
}

.gallery_product-content-head h4 {
  font-size: 16px;
  font-weight: 800;
}

.gallery_product-content-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.gallery_product-content-head span {
  font-size: 17px;
  color: #8d126e;
  font-weight: 700;
}

.gallery_product-top {
  position: relative;
}

.gallery_product-top:after {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.3) 0,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.3) 0,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 0,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
}

.food-info span {
  font-size: 14px;
  color: #4e555d;
  padding-right: 30px;
}

.food-info span b {
  font-weight: 700;
  padding-right: 5px;
}

.white-checkbox .checkmark {
  border: 2px solid #fff;
}

.white-checkbox input:checked ~ .checkmark {
  border: 2px solid #8d126e;
}

.info-list {
  display: flex;
  background: #8d126e;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 9px 11px;
}

.screen-div-info {
  position: fixed;
  right: 0;
  bottom: 30px;
  z-index: 9;
}

.info-list li a i {
  padding-right: 7px;
  font-size: 19px;
  font-weight: 300;
  position: relative;
  top: 1px;
}

.info-list li a {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  border-right: 1px solid #ddd;
  padding-right: 10px;
  margin-right: 10px;
}

.info-list li:last-child a {
  padding-right: 0;
  margin-right: 0;
  border-right: 0px solid #ddd;
}

.info-list {
  display: flex;
  background: #8d126e;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 9px 20px;
  box-shadow: -2px 0 11px 4px rgb(141 18 110 / 27%);
}

.action-box-head {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  z-index: 1;
}

.action-box-head-L a {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.action-box-head-L {
  display: flex;
  align-items: center;
}

.edit-btn {
  background: #fff;
  border-radius: 50%;
  font-size: 22px;
  color: #292d32;
}

.delete-btn {
  background: #292d32;
  border-radius: 50%;
  font-size: 16px;
  color: #fff;
}

.text-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 6px;
  border-radius: 33px;
  background: #fff;
  color: #fff !important;
  width: 112px;
  font-size: 14px;
}

.btn i {
  padding-right: 10px;
  font-weight: 300;
  font-size: 21px;
}

.breadcrumb-item.active {
  color: #8d126e;
}

.breadcrumb-item {
  font-size: 15px;
}

.breadcrumb-item a {
  color: #707070;
}

.title-inner {
  font-size: 18px;
  /* text-transform: uppercase; */
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 5px;
}

.order-detail figure img {
  width: 108px;
  height: 108px;
  border-radius: 50%;
}

.order-detail figure {
  margin-bottom: 15px;
}

.order-detail .food-info span {
  font-size: 16px;
  color: #292d32;
  padding-bottom: 10px;
  display: inline-block;
}

.food-info.food-info-break span {
  width: 48%;
}

.food-info.food-info-break span b {
  display: block;
}

.price-text {
  font-size: 18px;
  display: block;
  color: #8d126e;
  font-weight: 700;
  padding-bottom: 5px;
}

.text-food {
  font-size: 16px;
  padding-bottom: 5px;
  display: block;
}

.filter-menu-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.filter-text-button span {
  font-size: 16px;
  margin-right: 20px;
}

.number-of-select i {
  padding-right: 6px;
  color: #8d126e;
  font-size: 18px;
  position: relative;
  top: 1px;
}

.Unselect-box,
.select-all-box {
  font-size: 14px;
  color: #8d126e;
  text-decoration: underline;
  font-weight: 600;
  margin-right: 15px;
  background-color: #fff;
}

.number-of-select {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.minus,
.plus {
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  color: #292d32;
  font-weight: 700;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  width: 33px;
  justify-content: center;
}

.number input {
  display: flex;
  vertical-align: middle;
  text-align: center;
  border: 0;
  width: 35px;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  height: 100%;
}

.number {
  border: 1px solid #8d126e;
  border-radius: 4px;
  height: 29px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87px;
}

.add-dish-list {
  display: flex;
}

.dish-name-content {
  display: flex;
  align-items: center;
}

.dish-name-content figcaption h3 {
  font-size: 13px;
  font-weight: 700;
  /* padding-left: 20px; */
  word-break: keep-all;
}

.dish-name-content figure {
  flex: 0 0 86px;
}

.dish-name-content figure img {
  width:55px;
  height: 55px;
  border-radius: 50%;
}

/* .all-dish-item:nth-child(1) {
    width: 18%;
}

.all-dish-item:nth-child(2) {
    width: 12%
}

.all-dish-item {
    width: 20%
}

.all-dish-item:last-child {
    width: 5%;
} */

.add-dish .delete-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8d126e;
}

.min-height-box {
  min-height: 86px;
  display: flex;
  align-items: center;
}

.common-modal .modal-dialog {
  margin: 0;
}

.MuiDialog-container .MuiPaper-root {
  border-radius: 22px;
}

/* .common-key-word .form-control {
    border: 1px solid #bc1873;
} */

/* 
.tags-input-wrapper .react-tagsinput-tag {
    background-color: #8d126e;
    color: #fff;
    border-radius: 6px;
    padding: 4px 12px;
    margin: 2px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
} */

.common-key-word .react-tagsinput--focused {
  border-color: #8d126e;
}

.common-key-word .react-tagsinput-tag {
  background-color: #8d126e;
  border-radius: 6px;
  border: 0px solid #a5d24a;
  color: #fff;
  display: inline-block;

  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 12px;
  margin: 2px 2px;
}

a.react-tagsinput-remove {
  font-size: 20px;
  color: #fff;
  font-weight: 300;
  line-height: normal;
  position: relative;
  top: 2px;
}

.file.file--upload button {
  border: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #969696 !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #969696 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #969696 !important;
}

.common-key-word {
  position: relative;
}

.css-1rhbuit-multiValue {
  background-color: #8d126e !important;
  color: #fff !important;
  border-radius: 4px !important;
}

body .css-12jo7m5 {
  color: #fff !important;
}

.forgotPasswordBtn {
  text-align: right;
}

.forgotPasswordBtn button {
  display: inline-block;
  width: auto;
}

/****/

span.text-danger {
  width: 100%;
  display: block;
  margin-bottom: 7px;
}

.banner-top,
.banner-top .container {
  height: 100%;
}

.nav-R .collapse:not(.show) {
  display: initial;
  right: -227px;
}

.icon-with-input {
  position: relative;
}

.gallery_product .food-info {
  display: flex;
  flex-wrap: wrap;
}

.gallery_product .food-info span {
  padding-right: 20px;
}

.sidebar .accordion-header {
  font-size: unset;
}

.patterns-banner figure img {
  width: 100%;
}

.scrollbar-bg {
  overflow: hidden;
  height: 45px;
  margin: 0px 10px;
}

.scrollbar-bg .filter-head a {
  height: 40px;
}

.scrollbar-bg .filter-head {
  min-height: 80px;
}

.css-1s2u09g-control.is-invalid {
  border-color: #dc3545 !important;
}

.css-1s2u09g-control.is-invalid .css-1s2u09g-control,
.css-1s2u09g-control .css-1s2u09g-control {
  width: 100%;
  box-shadow: none !important;
  border: none !important;
}

.css-1pahdxg-control {
  width: 100%;
  box-shadow: none;
}

.avatar-upload .con-tooltip {
  right: -48px;
}

.black-btn {
  background: #212529;
}

.css-1pahdxg-control:focus {
  border-color: #198754 !important;
}

.css-1pahdxg-control {
  box-shadow: 0 0 0 1px #198754 !important;
  border-color: #198754 !important;
}

/* ********************myOrder******************************************** */

.my-orders-tabs li .nav-link {
  background: #f7f7f7;
  border-radius: 6px;
  font-size: 15px;
  color: #707070;
  font-weight: 400;
  padding: 10px 10px;
  margin-bottom: 0;
  border: 0;
  margin-right: 20px;
}

.nav-tabs {
  border-bottom: 0;
}

.my-orders-head {
  margin-bottom: 25px;
}

.my-orders-tabs li .nav-link.active {
  background: #8d126e;
  color: #fff;
}

.my-orders-title {
  font-size: 18px;
  font-weight: 800;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.pagination-box .page-link {
  border: 1px solid #d1d7db;
  margin: 0 10px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #707070;
  border-radius: 0;
}

.pagination-box .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #8d126e;
  border-color: #8d126e;
}

.pagination-box .page-item:not(:first-child) .page-link {
  margin-left: 0;
}

.pagination-box .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-box .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-box .page-link:hover {
  z-index: 2;
  color: #fff;
  background-color: #8d126e;
  border-color: #8d126e;
}

.pagination-box .page-link:focus {
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #d1d7db;
  color: #707070;
}

.reject-text-area textarea.form-control {
  min-height: 55px;
  height: auto;
  resize: none;
  font-size: 15px;
}

.black-btn {
  background: #292d32;
}

.step-button.btn {
  width: auto;
  padding: 0 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-timer small b {
  color: #8d126e;
  font-size: 14px;
  font-weight: 700;
}

.timer {
  background: rgb(208 52 44 / 15%);
  padding: 5px 16px;
  border-radius: 33px;
  font-size: 14px;
  color: #d0342c;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: 5px;
}

.btn-search {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  border: 0;
  background: 0 0;
  font-weight: 600;
  font-size: 20px;
  color: #707070;
}

.search-box {
  position: relative;
  width: 137px;
}

.search-box input.form-control {
  padding-left: 45px;
  background: rgb(112 112 112 / 6%);
  border: 0;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  height: 45px;
}

.search-box {
  position: relative;
  flex: 0 0 304px;
}

.search-box input.form-control::-webkit-input-placeholder {
  color: #707070;
}

.search-box input.form-control:-ms-input-placeholder {
  color: #707070;
}

.search-box input.form-control::placeholder {
  color: #707070;
}

.disbled-btn {
  background-color: #f7f7f7;
  opacity: 1;
  border-color: #f7f7f7;
  color: rgb(78 85 93 / 44%);
  pointer-events: none;
}

.without-tabs {
  border: 0;
  box-shadow: 0 1px 4px rgb(0 0 0 / 11%);
  border-radius: 6px;
  margin-bottom: 25px;
  padding: 20px 20px;
}

.Dishes-list.my-order-list li {
  align-items: flex-start;
  margin-bottom: 30px;
}

.Dishes-list.my-order-list {
  flex-direction: column;
}

.price {
  font-size: 18px;
  color: #8d126e !important;
  font-weight: 700;
}

.my-order-food-info {
  flex-wrap: wrap;
}

.my-order-food-info.food-info.food-info-break .full-food-info {
  width: 100%;
}

.my-order-food-info span {
  font-size: 14px;
  color: #4e555d;
  padding-right: 30px;
  margin-bottom: 12px;
}

.my-order-food-info.food-info.food-info-break span {
  width: 33%;
}

.Print-label-box .modal-dialog {
  max-width: 902px;
}

.Print-label-head span {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

.Print-label-head {
  text-align: left;
}

.Print-label-body {
  text-align: left;
}

.Print-label-body h4 {
  font-size: 19px;
  font-weight: 800;
  text-transform: uppercase;
  padding-bottom: 15px;
}
.print-info-table {
  border: 1px solid #ddd;
  width: 90%;
  margin: auto;
  margin-top: 20px;
}

.print-info-table th, .print-info-table td {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}

.print-info-table th:last-child, .print-info-table td:last-child {
  border-right: 0;
}

.print-info-table td span {
  display: block;
  font-size: 18px;
  font-weight: 600;
}
.Print-label-body span.text-info-print {
  margin-bottom: 10px;
  display: block;
  margin-right: 55px;
}

.text-info-print {
  font-size: 18px;
  font-weight: 400;
}

.text-info-print b {
  font-weight: 700;
}

select.form-control.bg-grey-select {
  background: #f7f7f7 url(../images/arrow-icon.png);
  border: 0;
  background-position: right 10px center;
  background-repeat: no-repeat;
}

select.form-control:focus {
  outline: 0;
  box-shadow: none;
}

.my-earnings-head h4 {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #292d32;
  font-weight: 700;
}

.my-earnings-head h4 i {
  margin-right: 10px;
  color: #8d126e;
  font-size: 24px;
  font-weight: 400;
}

.rating-star {
  display: flex;
}

.rating-star li i {
  font-size: 20px;
  color: #8d126e;
}

.rating-star li {
  margin-right: 3px;
}

.define-rating li {
  display: flex;
  align-items: center;
}

.rate-star {
  font-size: 14px;
  color: #292d32;
  padding-right: 15px;
}

.rate-bar {
  width: 246px;
  background: rgb(78 85 93 / 10%);
  height: 6px;
  border-radius: 10px;
}

.rate-percentage {
  padding-left: 15px;
  font-size: 14px;
  color: #292d32;
}

.total-rating {
  font-size: 28px;
  margin-bottom: 10px;
}

.chef-reviews-box small {
  font-size: 15px;
  color: #292d32;
  padding-bottom: 5px;
  display: block;
}

.chef-reviews-box h3 {
  font-size: 18px;
  color: #292d32;
  font-weight: 700;
  padding-bottom: 5px;
}

.chef-reviews-box p {
  font-size: 15px;
  color: #292d32;
}

.chef-reviews-box {
  margin-bottom: 24px;
}

.similar-shadow {
  box-shadow: 0 1px 4px rgb(0 0 0 / 11%);
  border-radius: 6px;
  padding: 30px 30px;
}

.profile-box figure img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.profile-box figure {
  margin-bottom: 15px;
}

.profile-box h3 {
  font-size: 18px;
  color: #292d32;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.profile-box .contact-info li {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #4e555d;
  margin-bottom: 8px;
}

.profile-box .contact-info li i {
  font-size: 22px;
  color: #8d126e;
  margin-right: 10px;
}

.profile-box p {
  font-size: 14px;
  color: #4e555d;
  line-height: 23px;
}

.profile-box .contact-info {
  margin-bottom: 10px;
}

/*--------------------------------------------------------------------------------------*/
/*toggle-switch
/*--------------------------------------------------------------------------------------*/
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 22px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: rgb(141 18 110 / 60%);
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.notifications-box ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-bottom: 7px;
}

.switch-toggle {
  display: flex;
}

.notifications-box-head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.notifications-inner {
  width: 60px;
  font-size: 14px;
  color: #292d32;
  text-align: center;
}

.notifications-list li span {
  font-size: 14px;
  color: #707070;
}

.profile-title {
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
}

.profile-box {
  margin-bottom: 30px;
}

.tabs-similer .nav-tabs {
  justify-content: center;
  padding: 15px 30px 0;
  border-bottom: 1px solid #ddd;
}

.setting-right .nav-tabs .nav-link {
  margin-bottom: 0;
  background: 0 0;
  border: 0;
  border-top-left-radius: initial;
  border-top-right-radius: initial;
  color: #707070;
  font-size: 18px;
  font-weight: 600;
  padding: 20px 20px !important;
}

.setting-right .nav-tabs .nav-link.active {
  color: #8d126e;
  position: relative;
}

.setting-right .nav-tabs .nav-link.active:after {
  border-bottom: 3px solid #8d126e;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  content: "";
}

.setting-right .similar-shadow {
  padding: 0;
}

.setting-right .tab-content {
  padding: 20px 30px 30px;
}

.link-text-group {
  position: relative;
}

.verify-text {
  font-size: 15px;
  color: #8d126e;
  font-weight: 600;
  text-decoration: underline;
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.link-text-group input.form-control {
  padding-right: 65px;
}

.tabs-similer .nav-tabs li {
  margin: 0 15px;
}

.forget-passowrd {
  font-size: 15px;
  color: #8d126e;
  font-weight: 700;
}

.verification-box input {
  margin: 0 10px;
  text-align: center;
  font-weight: 700;
}

.inner-banner img {
  height: 360px;
  width: 100%;
  object-fit: cover;
}

.MuiInputBase-root {
  height: 28px;
}

.MuiInputBase-input {
  padding: 0;
}

.MuiFormLabel-root.Mui-error {
  color: #d32f2f;
  line-height: 0px;
  overflow: visible;
  font-size: 13px;
}

/* .form-control-date-picker label {
    height: 36px;
    padding: 0;
    color: #d32f2f;
    overflow: visible;
    line-height: 7px !important;
} */

.gallery_product-content-head h4 {
  white-space: initial;
  word-break: keep-all;
  padding-right: 20px;
}

span.form-bottom-text a {
  font-size: 16px;
  color: #8d126e !important;
  font-weight: 700;
}

span.form-bottom-text {
  font-size: 16px;
  color: #4e555d !important;
  font-weight: 400;
  display: block;
  text-align: center;
  margin-top: 40px;
}

select.form-control.bg-grey-select {
  background: #f7f7f7 url(../images/arrow-icon.png);
  border: 0;
  background-position: right 10px center;
  background-repeat: no-repeat;
}

select.form-control:focus {
  outline: 0;
  box-shadow: none;
}

.my-earnings-head h4 {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #292d32;
  font-weight: 700;
}

.my-earnings-head h4 i {
  margin-right: 10px;
  color: #8d126e;
  font-size: 24px;
  font-weight: 400;
}

.rating-star {
  display: flex;
}

.rating-star li i {
  font-size: 20px;
  color: #8d126e;
}

.rating-star li {
  margin-right: 3px;
}

.define-rating li {
  display: flex;
  align-items: center;
}

.rate-star {
  font-size: 14px;
  color: #292d32;
  padding-right: 15px;
}

.rate-bar {
  width: 246px;
  background: rgb(78 85 93 / 10%);
  height: 6px;
  border-radius: 10px;
}

.rate-percentage {
  padding-left: 15px;
  font-size: 14px;
  color: #292d32;
}

.total-rating {
  font-size: 28px;
  margin-bottom: 10px;
}

.chef-reviews-box small {
  font-size: 15px;
  color: #292d32;
  padding-bottom: 5px;
  display: block;
}

.chef-reviews-box h3 {
  font-size: 18px;
  color: #292d32;
  font-weight: 700;
  padding-bottom: 5px;
}

.chef-reviews-box p {
  font-size: 15px;
  color: #292d32;
}

.chef-reviews-box {
  margin-bottom: 24px;
}

.dish-review-box {
  box-shadow: 0 1px 4px rgb(0 0 0 / 11%);
  border-radius: 6px;
  padding: 20px 20px;
}

.dish-review-box-L figure {
  width: 66px;
  height: 66px;
}

.dish-review-box-L figure img {
  border-radius: 50%;
  width: 100%;
}

.dish-review-box-L h4 {
  font-size: 20px;
  color: #292d32;
  font-weight: 700;
  padding-left: 15px;
}

.dish-review .col-md-4 {
  margin-bottom: 20px;
}

.faq-inner-purple .accordion-button:not(.collapsed) {
  color: #8d126e;
}

/* .faq-inner-purple .accordion-button:not(.collapsed)::after{background-image:url(../images/minus-icon-purple.png)} */
.setting-right .nav-tabs .nav-link {
  margin-bottom: 0;
  background: 0 0;
  border: 0;
  border-top-left-radius: initial;
  border-top-right-radius: initial;
  color: #707070;
  font-size: 18px;
  font-weight: 600;
  padding: 20px 20px !important;
}

.setting-right .nav-tabs .nav-link.active {
  color: #8d126e;
  position: relative;
}

.setting-right .nav-tabs .nav-link.active:after {
  border-bottom: 3px solid #8d126e;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  content: "";
}

.setting-right .similar-shadow {
  padding: 0;
}

.setting-right .tab-content {
  padding: 20px 30px 30px;
}

.span .profile-title-text img {
  width: 40px;
  height: 40px;
}

.delivery-radio-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.inner-title-top-dish .con-tooltip {
  right: -28px;
  top: -2px;
}

.inner-title-top-dish h3.inner-title {
  position: relative;
  display: inline-flex;
}

.number-unactive {
  border-color: #d32f2f;
}

span.profile-title-text {
  display: flex;
  align-items: center;
}

span.profile-title-text figure {
  flex: 0 0 55px;
  margin-right: 8px;
}

span.profile-title-text {
  padding-right: 0px;
}

span.profile-title-text small {
  word-break: initial;
  white-space: normal;
  line-height: 1.4;
  font-size: 14px;
  text-align: left;
}

.MuiTypography-h6 {
  white-space: initial;
  word-break: break-word;
}

.help-center-content {
  background: rgb(141 18 110 / 11%);
  padding: 40px 40px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.help-center-content span {
  font-size: 38px;
  color: #8d126e;
  margin-bottom: 15px;
}

.help-center-content h4 {
  font-size: 18px;
  color: #292d32;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.help-center-content a {
  font-size: 16px;
  color: #292d32;
  font-weight: 700;
  margin-bottom: 10px;
}

.help-center-content small {
  font-size: 15px;
  color: #292d32;
}

.ingredients-box.active .form-control {
  background-color: #8d126e;
  border-color: #8d126e;
  color: #fff;
}

.accordion-body ul li {
  list-style: initial;
  list-style-position: outside;
}

.css-26l3qy-menu {
  background-color: #fff !important;
  z-index: 999 !important;
}

ul.Delivery-content li b {
  padding-right: 10px;
}

.loading-indicator:before {
  content: "";
  background: rgba(0, 0, 0, 0.9);
  opacity: 0.2;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background-size: 50px;
}

.loading-indicator:after {
  content: url(../images/logo.png);
  position: fixed;
  width: 100%;
  top: 40%;
  left: 0;
  z-index: 1001;
  color: #fffb00;
  z-index: 99999;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: PFDinMonoRegular;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #8d126e;
  transition: width 0.6s ease;
}

.rating-box-bg ul {
  max-width: 600px;
}

.rating-box-bg li {
  display: flex;
  align-items: center;
}

.rating-box-bg li .progress {
  flex: 1;
  margin: 0px 15px;
  height: 7px;
}

.rating-box-bg li span {
  min-width: 45px;
}

.policy a {
  color: #2196f3;
}

.policy ul {
  list-style: disc;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    font-size: 1rem !important
}
.messageText {
  margin: 10px 0px;
}

.dish-row .col-md-4 {
  display: flex;
  align-items: center;
}

.dish-info {
  width: 100%;
}

.our-clients .owl-nav {
  display: none;
}

.our-clients .owl-theme .owl-dots .owl-dot span {
  margin: 0;
}

.dropdown.drop-down-profile a.dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown.drop-down-profile a.dropdown-item i {
  padding-right: 7px;
  padding-top: 2px;
}

.doller-total-earning {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #8d126e;
  font-weight: 700;
}

.doller-total-earning i {
  font-weight: 500;
  font-size: 20px;
  padding-top: 2px;
  padding-right: 5px;
}

.about-steps-box figcaption h4 p {
  font-size: 18px;
  word-break: break-word;
  font-weight: 900;
  padding: 0 13px;
}

.accordion-body ul {
  padding-left: 20px;
}

.banner-top-L h1 strong {
  font-size: 46px;
  line-height: 53px;
  color: #fff;
  font-weight: 700;
  font-family: var(--body-font);
}

p.banner-top-L-content p p {
  font-size: 18px;
  line-height: 28px;
}

.setting-right .upload-documents-btn-tabs .nav-tabs .nav-link {
  background: #8d126e;
  color: #fff;
  border-radius: 10px;
  padding: 10px 12px !important;
  font-size: 17px;
}

.tabs-similer.upload-documents-btn-tabs .nav-tabs {
  justify-content: flex-end;
  padding-bottom: 15px;
}

.banner-main-box {
  position: relative;
}

.banner-main-box:after {
  background: rgb(0 0 0 / 62%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
}

/* .uploading-group.upload-document {
    justify-content: flex-end;
} */

.dashboard-top-box-L {
  padding-right: 5px;
}

.my-orders-body .accordion-header {
  margin-bottom: 0;
  /* padding: 12px 20px 10px; */
}

.dash-accordion .accordion-body {
  padding: 0;
  /* padding: 5px 20px 25px; */
}

.banner-top-L small span p {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.delivery-main-head .MuiInputBase-root {
  height: 28px;
}

.delivery-main-head .tooltip {
  bottom: 11px;
  left: 26px;
}

.delivery-main-head .tooltip::after {
  left: 2px;
}

figcaption.Dishes-list-R br {
  display: none;
}

figcaption.Dishes-list-R {
  display: flex;
  flex-wrap: wrap;
}

figcaption.Dishes-list-R h4 {
  width: 100%;
}

ul.Delivery-content {
  padding-left: 0;
}

.my-menu-content .tooltip p {
  max-height: none;
  overflow: unset;
}

.delivery-main-head .my-menu-content .tooltip {
  top: 39px;
  bottom: auto;
  left:8px;
}

.my-menu-content .tooltip:after {
  bottom: auto;
  top: -7px;
  border-top: none;
  border-bottom: solid 7px #c7c7c7;
  border-left: solid 7px transparent;
  border-right: solid 7px transparent;
}

.versioncode {
  position: fixed;
  bottom: 0px;
  left: 0px;
  font-size: 15px;
  color: white;
  width: 212px;
  padding: 10px;
  text-align: center;
}

.versioncode:hover {
  color: #fff;
}

/* width */
::-webkit-scrollbar {
  height: 8px;
}

.pagination-main::-webkit-scrollbar-thumb {
  background: #8d126e;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
}

/***/

.intro-slider .item figure img {
  height: 292px;
  object-fit: cover;
  border-radius: 10px;
}

.intro-slider .owl-nav.disabled {
  display: block !important;
}

.intro-slider button.owl-next,
.intro-slider button.owl-prev {
  position: absolute;
  right: -54px;
  top: 0;
  bottom: 0;
  background: transparent;
}

.intro-slider button.owl-prev {
  left: -54px;
  right: auto;
}

.intro-slider button.owl-next span,
.intro-slider button.owl-prev span {
  font-size: 110px;
  color: #fd207a;
  font-weight: 600;
}

.intro-slider .owl-dots {
  display: none;
}

.intro-slider button.owl-next:hover {
  background: transparent !important;
}

.contact-info-box {
  background: url(../images/contact-info-bg.png);
  position: relative;
  height: 335px;
  padding: 40px 0;
  display: flex;
}

.contact-info-box:after {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.9+0,0+100 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
}

.contact-info-content {
  z-index: 9;
  position: relative;
}

.contact-info-content h2 {
  font-size: 34px;
  color: #fff;
  text-transform: uppercase;
}

ul.banner-contact-list {
  display: flex;
  align-items: center;
  padding-top: 30px;
}

ul.banner-contact-list li figure {
  width: 68px;
  height: 68px;
  background: #fd207a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 25px;
}

ul.banner-contact-list li {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

ul.banner-contact-list li figure i {
  font-size: 25px;
  color: #fff;
}

ul.banner-contact-list li figcaption h5 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 1px;
  padding-bottom: 5px;
  font-size: 21px;
}

ul.banner-contact-list li figcaption a {
  color: #fff !important;
  font-size: 16px;
  font-weight: 600;
}

.testimonaials-slider button.owl-next,
.testimonaials-slider button.owl-prev {
  position: absolute;
  right: -54px;
  top: 0;
  bottom: 0;
  background: transparent;
}

.testimonaials-slider button.owl-prev {
  left: -54px;
  right: auto;
}

.testimonaials-slider button.owl-next span,
.testimonaials-slider button.owl-prev span {
  font-size: 110px;
  color: #fd207a;
  font-weight: 600;
}

.testimonaials-slider .owl-nav.disabled {
  display: block;
}

.testimonaials-slider .owl-dots {
  display: none;
}

.testimonaials-slider .item figure i {
  font-size: 36px;
  color: rgb(78 85 93 / 28%);
}

.testimonaials-slider .item figure {
  margin-bottom: 10px;
}

.testimonaials-slider .item figcaption h4 {
  font-size: 22px;
  color: #292d32;
  margin-bottom: 12px;
}

.testimonaials-slider .item figcaption p {
  font-size: 16px;
  color: #292d32;
}

.testimonaials-slider .item {
  box-shadow: 0 0 4px rgb(93 87 87 / 14%);
  border-radius: 10px;
  padding: 30px 30px;
  margin: 0 5px;
}

.testimonaials-slider .owl-stage-outer {
  padding: 10px 0;
}

.testimonaials-slider button.owl-next:hover {
  background: transparent !important;
}

.intro-content-head h2 {
  font-size: 34px;
  text-transform: uppercase;
  font-weight: 700;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: 0;
}

.react-datepicker {
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  transform-origin: top center;
  border: 0;
}

.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__current-month {
  font-weight: 400;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
  color: rgb(0 0 0 / 57%);
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #92136d;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: transparent !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: rgb(204 204 204 / 91%) !important;
}

.react-datepicker-wrapper input {
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.react-datepicker-wrapper input {
  width: 180px;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #92136d;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  color: #fff;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 0;
  z-index: 1000;
}
.react-datepicker-wrapper input {
  width: 180px;
  padding-left: 10px;
  padding-right: 40px;
}
.catering-content-box ul li {
  list-style-position: inside;
}

ol li {
  list-style-position: inside;
}

ol,
ul {
  padding-left: 0;
}

.react-datepicker__day--keyboard-selected.react-datepicker__day--highlighted {
  background-color: #92136d !important;
  color: #fff;
}

/* .react-datepicker__day--selected {
    background: transparent;
    color: rgb(0 0 0 / 57%)  !important;
} */

.dropdown.drop-down-profile .dropdown-item.active {
  background-color: #8d126e;
}

/* .react-datepicker__day--keyboard-selected {
    background-color: #92136d !important
} */

body .css-u791bf-MuiPickersArrowSwitcher-root-MuiClockPicker-arrowSwitcher {
  z-index: 999999 !important;
}

.testimonaials-slider .owl-stage {
  display: flex;
}
.testimonaials-slider .owl-item {
  float: none;
  display: flex;
  width: 100%;
}
.testimonaials-slider .item {
  width: 100%;
}

.food-info-break {
  display: flex;
  flex-wrap: wrap;
}

.Dishes-list.dishes-list-edit {
  flex-wrap: wrap;
}

.Dishes-list.dishes-list-edit li {
  width: 25%;
  margin-right: 0;
  margin-bottom: 30px;
}

.Dishes-list.dishes-list-edit figcaption.Dishes-list-R {
  flex: 1;
}
.cheftc-box {
border: 1px solid #8d126e;
color: #8d126e ;
font-weight: 500;
box-shadow: 0px 0px 2px #8d126e;
cursor: pointer;
text-wrap: nowrap;

}
.cheftc-box:hover{
  color: #2196f3 ;
}
.custom-radiobox .checkmark{
  top: -12px !important;
}
.my-order-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}
.my-order-title h3{
  font-weight: 600;
  text-transform: capitalize;
  border-bottom: 2px solid gray;
  margin-bottom: 1rem;
  padding-bottom: 4px;
}
.tooltip-under:after{
  left:95%
  }

  .update-con-tooltip .tooltip-under{
    left: -321px;
    bottom: 44px;
    z-index: 9999;
  }

  .tooltip-order-date:after{
    left:95%
    }

  .update-con-tooltip .tooltip-order-date{
    left: -321px;
    bottom: 133px;
    z-index: 9999;
  }
  .add-dish-wrapper .tooltip,.upload-dish-wrapper .tooltip, .edit-dish-wrapper .tooltip{
    bottom: 25px;
  }
  