@media only screen and (min-width:1024px) and (max-width:1440px),
only screen and (min-width: 1441px) {
    .filter-button {
        font-size: 14px;
    }

    .dash-head h3 {
        font-size: 20px;
    }

    .min-height {
        height: 34px;
    }

    .filter-text-button-R .btn {
        width: 185px;
        font-size: 14px;
    }

    .dash-R .filter {
        width: 33%;
    }

    .action-box-head-L a {
        width: 32px;
        height: 32px;
    }

    .edit-btn {
        font-size: 16px;
    }

    .text-info {
        padding: 3px 4px;
        width: 90px;
        font-size: 12px;
    }

    .gallery_product-top img {

        height: 200px;
    }

    .gallery_product-content {
        padding: 10px 15px;
    }

    .gallery_product .food-info span {
        padding-right: 20px;
        line-height: 22px;
    }

    .gallery_product-content-head h4 {
        font-size: 15px;
    }

    .scrollbar-bg .filter-head a {
        height: 36px;
        line-height: 22px;
    }

    .action-box-head-L i.ri-delete-bin-fill {
        font-size: 18px;
    }

    .sidebar-L {
        width: 200px;
    }

    .dash-R {
        padding-left: 230px;
    }

    .sidebar-L li a {
        font-size: 14px;
    }

    .sidebar-L ul li {
        margin-bottom: 5px;
    }

    .dashboard-top-box-L b {
        font-size: 22px;
        padding-bottom: 2px;
    }

    .dashboard-top-box-L span {
        font-size: 14px;
        line-height: 1.5;
        display: block;
    }

    .dashboard-top-box-R span {
        width: 42px;
        height: 42px;
    }

    .dashboard-top-box {
        height: 100%;
        align-items: flex-start;
    }


    .my-orders-body h3 {
        font-size: 18px;
    }


    .my-earnings-head h4 {
        font-size: 14px;
    }

    .earnings-box button.accordion-button {
        padding: 10px 15px;
    }

    .dashboard-top-box {
        padding: 13px 15px;
    }

    .total-rating {
        font-size: 24px;
    }

    h3.my-orders-title {
        font-size: 16px;
    }

    .accordion .accordion-item {
        margin-bottom: 8px !important;
    }

    .faq-inner .accordion-button {
        padding: 15px 20px;
        font-size: 14px;
    }


    .similar-shadow.profile-box {
        padding: 20px 15px;
        margin-bottom: 20px;
    }

    .uploding-list li {
        width: 95px;
        height: 95px;
    }

    .profile-box figure img {
        width: 100px;
        height: 100px;
    }

    .setting-right .upload-documents-btn-tabs .nav-tabs .nav-link {
        padding: 6px 12px !important;
        font-size: 14px;
    }

    .dish-head {
        padding-bottom: 10px;
    }

    .Dishes-list li {
        align-items: flex-start;
    }

    figcaption.Dishes-list-R br {
        display: none;
    }

    .Dishes-list-R span {
        display: block;
    }

    .accordion-body ul {
        padding-left: 0;
        padding-top: 0px;
    }

    .Delivery-content li {

        font-size: 14px;
    }

    .step-button.btn {
        font-size: 14px;
        height: 38px;
    }

    .three-combine-btn {
        margin-top: 10px !important;
    }

    .Dishes-list-R span {
        font-size: 12px;
        line-height: normal;
    }

    .three-combine-btn a.btn {
        line-height: 30px;
        font-size: 14px;
    }

    .dash-accordion button.accordion-button {
        padding: 0;
    }

    .profile-title-text img {
        width: 40px;
        height: 40px;
    }

    span.profile-title-text figure {
        flex: 0 0 40px;
    }

    .sidebar-L {
        top: 80px;
    }

    .shape-1 img {
        width: 65%;
    }

    .shape-1 {

        top: 0
    }

    .shape-2 img {
        width: 65%;

    }

    .shape-2 {
        display: none;
    }

    .similer-title-head h2 {
        font-size: 30px;
    }

    .food-box-similer figcaption h3 {
        font-size: 18px;
    }

    .btn {
        height: 50px;
    }

    .footer-top-left figure img {
        max-width: 150px;
    }

    .banner-top-L h1 strong {
        font-size: 44px;
    }

    .sidebar {
        left: -343px;
        transition: .5s;
        top: 110px
    }

    .menu-fold {
        display: flex !important;
        position: absolute;
        right: -40px;
        top: 0;
        background: #8d126e;
        width: 40px;
        height: 42px;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 22px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px
    }

    .sidebar.open {
        transition: .5s;
        left: 0
    }

    a.menu-fold i {
        color: #fff
    }

    .sidebar.open i.ri-menu-fold-line {
        display: none
    }

    .sidebar.open i.ri-menu-unfold-line {
        display: block !important
    }

    .sidebar {
        left: -343px;
        transition: .5s;
        top: 110px
    }

    .menu-fold {
        display: flex !important;
        position: absolute;
        right: -40px;
        top: 0;
        background: #8d126e;
        width: 40px;
        height: 42px;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 22px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px
    }

    .sidebar.open {
        transition: .5s;
        left: 0
    }

    a.menu-fold i {
        color: #fff
    }

    .sidebar.open i.ri-menu-fold-line {
        display: none
    }

    .sidebar.open i.ri-menu-unfold-line {
        display: block !important
    }

    span.shape-4 img {
        width: 55%;
    }

    .about-us-content p {
        font-size: 14px;
    }

    .about-bottom-R h6 p {
        font-size: 24px;
    }

    .about-bottom-R strong {
        font-size: 1.25rem;
    }

    .about-bottom-R p {
        line-height: 1.6;
    }

    .col-md-6.about-bottom-R p {
        font-size: 14px;
    }

    .col-md-6.about-bottom-R p strong {
        font-size: 16px;
    }

    .terms-policy-content-box p {
        font-size: 14px;
    }

    .terms-policy-content-box ol li {
        font-size: 14px;
    }

    .gallery_product-top img {

        height: 180px;
    }

    .food-info span {
        font-size: 13px;
    }

    .gallery_product-content {
        padding: 10px 20px;
    }

    .filter-text-button-R .btn {
        width: 175px;
    }

    .filter-text-button-R .min-height {
        height: 40px;
        line-height: 26px;
        font-size: 15px;

    }

    .filter-text-button.justify-content-between {
        margin-top: 10px !IMPORTANT;
        margin-bottom: 10px !IMPORTANT;
    }

    .dash-head h3 {
        padding-bottom: 8px;
    }

    .filter-text-button {
        align-items: flex-start !important;
    }

    ul.info-list li span {
        display: none;
    }

    .info-list li i {
        padding-right: 0;
        margin-right: 0;
        padding-right: 0;
    }


    .delivery-main-head .tooltip {
        bottom: 15px;
        left: 26px;
        min-width: 330px;
    }

    .delivery-main-head .tooltip::after {
        left: 2px;
    }





    .tooltip p {
        max-height: 85px;
        font-size: 12px;
        padding: 10px 10px;
    }

    .btn {
        font-size: 12px;
        line-height: 23px;
        width: 132px;
    }

    .min-height {
        height: 37px;

    }

    .Print-label-body h4 {
        font-size: 16px;
    }

    .text-info-print {
        font-size: 15px;
    }

    .print-info-table li h3 {
        font-size: 16px;
    }

    .print-info-table li span {
        font-size: 15px;
    }

    .tooltip {
        min-width: 330px;
    }

}

@media only screen and (min-width:1300px) and (max-width:1500px) {
    .shape-1 {
        position: var(--position-abso);
        left: -8%;
        top: 0
    }

    /* .shape-1 img {
        width: 230px
    } */

    .shape-2 {
        bottom: auto
    }

    .shape-2 img {
        width: 200px
    }

    .shape-3 img {
        width: 282px
    }

    .wrapper-video {
        height: 500px
    }

    ul.info-list li span {
        display: none;
    }


}

@media only screen and (min-width:1500px) and (max-width:1800px) {
    .shape-3 {
        top: 0;
        right: -252px;
        position: absolute
    }
}

@media (max-width: 1199px) {
    .shape-1 img {
        width: 205px
    }

    .shape-1 {
        top: 6px
    }

    .similer-title-head h2 {
        font-size: 30px
    }

    .food-box-similer figure img {
        height: 163px
    }

    .food-box-similer figcaption h3 {
        font-size: 18px
    }

    .food-box-similer figcaption p {
        font-size: 14px
    }

    section#join-chef {
        padding-top: 25px
    }

    .wrapper-video {
        height: auto
    }

    .wrapper-video img {
        height: auto
    }

    .wrapper-video iframe {
        height: auto !important
    }

    .item-content {
        padding: 30px 30px 30px
    }

    .item-content h4 {
        font-size: 18px
    }

    .shape-3 img {
        width: 247px
    }

    .shape-2 img {
        width: 160px
    }

    .shape-2 {
        bottom: 73%
    }

    .banner-top-L h1 {
        font-size: 48px;
        line-height: 55px
    }

    .shape-4 img {
        width: 100px
    }

    .about-bottom-R h3 {
        font-size: 32px
    }

    .about-steps-box figcaption h4 {
        font-size: 20px
    }

    .pinq-text {
        font-size: 22px;
        line-height: 35px
    }

    .about-steps-box figcaption {
        height: 105px
    }

    .about-steps-box figcaption h4 {
        font-size: 16px
    }

    .about-steps-box figure {
        height: 110px
    }

    .shape-5 {
        display: none
    }

    .row.about-steps.mt-5 {
        margin-top: 20px !important
    }

    .pinq-text {
        font-size: 18px;
        line-height: 30px
    }

    .banner-inner-content h4 {
        font-size: 30px
    }

    .inner-banner img {
        height: 215px
    }

    .dish-name-content figcaption h3 {
        font-size: 11px;
        padding-left: 12px
    }

    .dish-name-content figure img {
        width: 50px;
        height: 50px
    }

    .min-height-box {
        min-height: 50px
    }



    .inner-header {
        padding: 12px 0
    }

    .sidebar-L {
        top: 64px;
        height: calc(100vh - 64px);
    }

    .padding-head {
        padding-top: 63px
    }

    .dash-R .col-md-9.m-auto {
        width: 100%
    }

    .sidebar {
        left: -343px;
        transition: .5s;
        top: 110px
    }

    .menu-fold {
        display: flex !important;
        position: absolute;
        right: -40px;
        top: 0;
        background: #8d126e;
        width: 40px;
        height: 42px;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 22px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px
    }

    .sidebar.open {
        transition: .5s;
        left: 0
    }

    a.menu-fold i {
        color: #fff
    }

    .sidebar.open i.ri-menu-fold-line {
        display: none
    }

    .sidebar.open i.ri-menu-unfold-line {
        display: block !important
    }

    .mar-t {
        margin-top: -50px
    }

    .patterns-banner.inner-space {
        padding-top: 25px
    }

    .form-box.row.mb-5.pb-5 {
        margin-bottom: 0 !important
    }

    .form-head h2 {
        font-size: 28px;
        padding-bottom: 0;
    }

    .help-center-content {
        padding: 40px 10px
    }

    .help-center-content h4 {
        font-size: 16px
    }

    .dash-bottom.help-center .col-md-8 {
        width: 100%
    }

    .dash-bottom.help-center .col-md-4 {
        width: 100%;
        margin-top: 20px
    }

    ol.breadcrumb {
        margin-bottom: 0
    }

    nav.breadcrumb-box {
        margin-bottom: 5px
    }

    .dash-bottom.order-detail .col-md-6 {
        width: 100%
    }

    .select-filter {
        width: 60%
    }

    .earnings-box .Delivery-content {
        margin-bottom: 0
    }

    .col-md-3.filter {
        width: 50%
    }

    .filter-button {
        margin-bottom: 15px
    }

    .filter-text-button-R {
        margin-left: 35px
    }

    .Dishes-list li {
        margin-right: 40px
    }

    .my-orders-tabs li .nav-link {
        margin-bottom: 16px
    }

    .food-info.food-info-break.my-order-food-info {
        width: 100%
    }

    .settings-Left {
        width: 35%
    }

    .setting-right {
        width: 65%
    }

    .tabs-similer .nav-tabs {
        padding: 15px 0 0;
        overflow: auto;
        white-space: nowrap;
        flex-wrap: nowrap;
        display: inherit
    }

    .setting-right .nav-tabs .nav-link {
        font-size: 16px;
        padding: 10px 10px !important
    }

    .tabs-similer .nav-tabs li {
        display: inline-block
    }

    .setting-right .combine-group .mb-3 {
        width: 100%
    }

    .notifications-box ul li {
        flex-direction: column;
        margin-bottom: 10px
    }

    .notifications-box-head span {
        display: none
    }

    .notifications-box-head .d-flex {
        width: 100%
    }

    .notifications-inner {
        width: 50%
    }

    .switch {
        margin: 0 15px
    }

    .uploding-list li {
        width: 25%;
        height: auto
    }

    .uploding-list li span img {
        height: 82px
    }

    .uploading-group .file .file-label {
        width: 100%;
        height: 82px
    }

    .uploading-group .file {
        height: auto
    }

    .uploding-list {
        width: 100%
    }

    .uploding-list li {
        width: 20%;
        height: auto;
        margin: 0 5px 10px
    }

    .file.file--upload span {
        font-size: 12px
    }

    .sidebar-L {
        width: 210px
    }

    .dash-R {
        padding-left: 230px
    }

    .uploading-group .file .file-label i {
        font-size: 22px;
        font-weight: 300;
        padding-bottom: 0;
        padding-right: 5px
    }

    .upload-document .uploding-list li {
        padding: 20px
    }

    .upload-document .uploding-list li:last-child {
        padding: 0
    }

    .upload-document .file {
        height: auto;
        width: 100%
    }

    .inner-heading h2 {
        font-size: 28px;
    }

    .dash-R {
        padding-top: 41px;
    }



    .dish-name {
        flex: 1;
    }

    .dish-name-content figure {
        max-width: 50px;
    }


    .row.mt-4.delivery-main-head .col-md-3 {
        width: 40%;
    }

    .row.mt-4.delivery-main-head .col-md-3:nth-child(2),
    .row.mt-4.delivery-main-head .col-md-3:nth-child(3) {
        width: 30%;
    }

    .banner-top-L h1 strong {
        font-size: 36px;
        line-height: 46px;
    }

    ul.info-list li span {
        display: none;
    }

    .info-list li i {
        padding-right: 0;
    }

    .tooltip {
        min-width: 200px;
    }


    .tooltip p {
        max-height: 80px;
        font-size: 13px;
        padding: 10px 15px;
    }

    .avatar-upload .con-tooltip {
        right: -25px;
    }

    .tooltip.r-tooltip {

        right: 0;
        left: auto;
    }

    .tooltip.r-tooltip::after {
        right: 0;
        left: auto;
    }

    
    .pagination-box .pagination-main {
        width: 70% !important;
    }
    
    .dish-list-inner .all-dish-item{width: 33%;}

        .Dishes-list.dishes-list-edit li {
            width: 47%;}
}

@media (max-width: 1024px) {
   .intro-content-head h2,
   .catering-content-box h2 {
       font-size: 24px;
   }

   .catering-content-box ul li {
       font-size: 16px;
   }

   .catering-content-box h3 {
       font-size: 18px;
   }

   .contact-info-content h2 {
       font-size: 24px;
   }

   ul.banner-contact-list li figcaption h5 {
       font-size: 18px;
   }

   ul.banner-contact-list li figcaption a {
       font-size: 14px;
   }

   ul.banner-contact-list li figure {
       width: 52px;
       height: 52px;
   }

   .intro-slider button.owl-next,
   .intro-slider button.owl-prev {
       right: 0;
   }

   .intro-slider button.owl-prev {
       left: 0;
       right: auto;
   }

   .intro-slider button.owl-next span,
   .intro-slider button.owl-prev span {
       font-size: 66px;
   }

   .testimonaials-slider button.owl-next,
   .testimonaials-slider button.owl-prev {
       right: 0;
   }

   .testimonaials-slider button.owl-prev {
       left: 0;
       right: auto;
   }

   .testimonaials-slider button.owl-next span,
   .testimonaials-slider button.owl-prev span {
       font-size: 66px;
   }
}

@media (max-width: 991px) {

    .row.join-customer .col-md-3 {
        width: 33%;
        margin-bottom: 35px
    }

    .row.join-customer {
        justify-content: center
    }

    .banner-top-L h1 {
        font-size: 38px;
        line-height: 48px
    }

    .banner-top-L p {
        font-size: 15px;
        padding-right: 0
    }

    .google-btn a img {
        width: auto
    }

    .navbar-brand.mob-logo-view {
        display: block !important
    }

    .navbar-toggler-icon {
        height: 40px;
        width: 45px
    }

    .navbar-brand.desktop-view-logo {
        display: none
    }

    .padding-head {
        padding-top: 58px
    }

    .banner-main-box {
        height: 425px
    }

    .row.join-customer .col-md-3 {
        width: 50%
    }

    .shape-2 {
        bottom: auto
    }

    .join-customer .text-center.mt-5 {
        margin-top: 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 0;
    }

    .item-content {
        padding: 30px 20px 30px
    }

    .food-box-similer figure img {
        height: 254px
    }

    .footer-content h3 {
        font-size: 18px
    }

    .footer-top-left figure img {
        width: 155px
    }

    .google-btn.mt-4 {
        margin-top: 15px !important
    }

    a.app-strore-btn.me-4 {
        margin-right: 10px !important
    }

    .footer-content li a {
        font-size: 14px
    }

    .footer-content li {
        padding: 2px 0
    }

    ul.footer-social li i {
        font-size: 20px !important
    }

    .btn {
        width: 178px;
        height: 49px;
        font-size: 15px
    }

    .footer-menu .google-btn a img {
        width: 125px
    }

    .pt-5.about-us-content p {
        font-size: 14px
    }

    .pt-5.about-us-content p {
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 1.7
    }

    .about-bottom-L figure img {
        height: auto;
        width: 100%
    }

    .about-bottom-R h3 {
        font-size: 24px;
        line-height: 35px
    }

    .about-bottom-R h3 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 10px
    }

    .about-bottom-R p {
        font-size: 14px
    }

    .about-us-description .col-md-5 {
        position: absolute;
        right: 0;
        width: 38%
    }

    .about-us-description .col-md-7.ps-4 {
        width: 100%
    }

    .pt-5.about-us-content {
        padding-right: 205px
    }

    .pt-5.about-us-content p {
        text-align: left !important
    }

    .banner-inner-content h4 {
        font-size: 26px
    }

    .about-bottom.space-similer {
        padding-top: 30px;
        padding-bottom: 30px
    }

    .inner-banner img {
        height: 165px
    }

    .inner-title {
        font-size: 14px
    }

    .dish-name-content figure img {
        width: 40px;
        height: 40px
    }

    .dish-name-content figcaption h3 {
        font-size: 13px;
        padding-left: 2px;
    }

    .number {
        width: 90px
    }

    .number input {
        font-size: 15px
    }

    .min-height-box {
        min-height: 40px
    }

    .tool-tip-box i {
        font-size: 14px
    }

    .min-height-box input.form-control {
        height: 40px
    }

    .min-height-box .coustom-checkbox {
        margin-right: 15px !important
    }

    .min-height-box .coustom-checkbox p {
        font-size: 12px
    }

    .dash-head h3 {
        font-size: 22px;
        padding-bottom: 12px
    }

    .add-dish p {
        font-size: 14px
    }

    .breadcrumb-item {
        font-size: 14px
    }

    nav.breadcrumb-box {
        line-height: normal
    }

    .btn-group-box {
        margin-top: 20px !important
    }

    .form-label {
        font-size: 14px
    }

    .Price-suggestion b {
        font-size: 13px
    }

    .Price-suggestion {
        font-size: 12px;
        line-height: 1.6
    }

    .dietary-list li .coustom-checkbox p {
        font-size: 14px
    }

    .avatar-upload .avatar-preview {
        width: 100px;
        height: 100px;
        margin: auto;
        margin-bottom: 20px;
    }

    .avatar-upload .avatar-edit input+label {
        right: 0;
        bottom: -6px;
    }

    .sidebar h3 {
        font-size: 16px
    }

    .sidebar .coustom-checkbox p {
        font-size: 14px
    }

    .sidebar .accordion-item {
        margin-bottom: 15px
    }

    .sidebar .sidebar-bottom-title {
        font-size: 16px
    }

    .sidebar {
        width: 300px
    }

    .sidebar {
        left: -300px
    }

    .patterns-banner.inner-space {
        padding-top: 0
    }

    .mar-t {
        margin-top: -23px
    }

    .dash-head p {
        font-size: 14px
    }

    .contact-info-list {
        width: 100%
    }

    .dashboard-top-box-L span {
        font-size: 14px
    }

    .dashboard-top-box-R span {
        width: 40px;
        height: 40px
    }

    .row.dashboard-box-main .col-md-4 {
        width: 50%;
        margin-bottom: 15px
    }

    .Dishes-list li {
        margin-right: 45px
    }

    .Delivery-content li {
        font-size: 14px
    }

    .order-detail .food-info span {
        font-size: 14px
    }

    .faq-inner .accordion-button {
        font-size: 15px
    }

    .accordion-body p {
        font-size: 14px
    }

    .form-box.row form.col-md-5.m-auto {
        width: 65%
    }

    .form-box.row .btn {
        width: 100%
    }

    .col-md-3.filter {
        width: 50%
    }

    .filter-button {
        margin-right: 13px
    }

    .gallery_product-content-head {
        flex-wrap: wrap
    }

    .gallery_product-top img {
        height: 148px
    }

    .filter-text-button span {
        font-size: 14px
    }

    .action-box-head-L a {
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-size: 18px
    }

    .text-info {
        font-size: 13px;
        padding: 3px 6px;
    }

    .my-orders-head {
        flex-wrap: wrap
    }

    .my-orders-tabs li .nav-link {
        margin-right: 10px
    }

    .my-orders-tabs li.nav-item {
        margin: 0 5px
    }

    .my-orders-tabs li .nav-link {
        margin-right: 0;
        width: 100%
    }

    .search-box {
        flex: 0 0 96%;
        margin: auto
    }

    .Dishes-list {
        flex-wrap: wrap
    }

    .my-orders-tabs {
        flex-wrap: nowrap;
        overflow: auto;
        overflow-y: hidden;
        white-space: nowrap
    }

    .my-orders-tabs li .nav-link {
        margin-right: 0
    }

    .three-combine-btn {
        flex-wrap: wrap
    }

    .Dishes-list li {
        margin-right: 30px;
        margin-bottom: 15px
    }

    .step-button.btn {
        margin-bottom: 13px
    }

    .my-order-food-info.food-info.food-info-break span {
        width: 50%
    }

    .dish-review .col-md-4 {
        margin-bottom: 20px;
        width: 100%
    }

    .dish-review-box-L h4 {
        font-size: 16px
    }


    .settings-Left {
        width: 45%
    }

    .setting-right {
        width: 55%
    }

    .profile-box h3 {
        font-size: 16px
    }

    .profile-box .contact-info li i {
        font-size: 18px;
        color: #8d126e;
        margin-right: 5px
    }

    .profile-box .contact-info li {
        font-size: 13px
    }

    .setting-right .tab-content {
        padding: 20px 20px 30px
    }

    .combine-table.d-flex.justify-content-between {
        flex-wrap: wrap;
        margin-bottom: 20px
    }

    .uploding-list li {
        width: 28%
    }

    .uploding-list li span img {
        height: 61px
    }

    .uploading-group .file {
        width: 100%;
        height: auto;
        display: flex
    }

    .file .file-label {
        flex-direction: row
    }

    .verify-text {
        font-size: 12px;
        top: -75px;
        right: 5px
    }

    .link-text-group input.form-control {
        padding-right: 15px
    }

    .btn.purple-btn {
        width: auto;
        /* height: auto; */
        min-width: 180px;
        padding: 4px 26px;
        font-size: 14px
    }
   
    .btn-group-add .btn.purple-btn {
        width: 178px;
        height: 50px;
        font-size: 14px;
        padding: 0.375rem 0.75rem;
    }

    /* .uploding-list li:last-child {
        width: 100%
    } */

    .uploading-group .file .file-label {
        width: 100%;
        height: auto;
        padding: 5px 10px;
        display: flex;
        align-items: center
    }

    section.main-wrapper-login.mar-t.mb-5 {
        overflow: hidden;
    }

    .row.mt-4.delivery-main-head .col-md-3 {
        width: 100%;
    }

    .banner-top-L h1 strong {
        font-size: 28px;
        line-height: 32px;
    }

    p.banner-top-L-content p p {
        font-size: 16px;
        line-height: 22px;
    }

    .versioncode{width: 194px;}

   .dish-list-inner .all-dish-item{width: 33%;}

.Dishes-list.dishes-list-edit li {
    width: 100%;
}


}


@media (max-width: 767px) {
    .banner-top-inner {
        flex-direction: column
    }

    .banner-top-R {
        position: absolute;
        right: 0;
        width: 18%;
        bottom: 0
    }

    .banner-top-L {
        flex: 0 0 100%
    }

    .banner-main-box {
        height: 360px
    }

    .banner-top {
        position: relative
    }

    .shape-1 img {
        width: 140px
    }

    .inner-tabs-link li a {
        font-size: 18px
    }

    .food-box-similer figure img {
        height: 186px
    }

    .play-btn:after {
        width: 60px;
        height: 60px
    }

    .play-btn:before {
        width: 70px;
        height: 70px
    }

    .item-content h4 {
        font-size: 16px
    }

    .shape-3 img {
        width: 185px
    }

    .similer-title-head h2 {
        font-size: 25px
    }

    .col-md-3.footer-content,
    .col-md-4.footer-content.ps-4 {
        width: 50%
    }

    .footer-top-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px
    }

    .footer-menu .google-btn.mt-4 {
        margin-top: 0 !important;
        flex-direction: column !important;
        display: flex;
        justify-content: flex-end;
        text-align: right;
    }

    .footer-menu  .app-strore-btn.me-4 {
        margin-right: 0px !important;
    }

    .footer-menu .google-btn a img {
        width: 140px
    }

    a.app-strore-btn.me-4 {
        margin-bottom: 15px
    }
    .google-btn a.app-strore-btn.me-4{margin-bottom: 0;}
    
   footer .google-btn a.app-strore-btn.me-4{margin-bottom: 10px;}


    .footer-social {
        justify-content: flex-end
    }

    .col-md-3.footer-content h3 {
        text-align: right
    }

    .footer-content .footer-social li {
        margin-right: 0;
        margin-left: 10px
    }

    .space-similer {
        padding-top: 35px;
        padding-bottom: 30px
    }

    .banner-top-L h1 {
        font-size: 36px
    }

    .shape-2 img {
        width: 122px
    }

    .btn {
        width: 178px;
        height: 50px
    }

    .item-content p {
        font-size: 14px
    }

    .banner-top-L p {
        line-height: 1.5
    }

    .banner-top-L h1 {
        font-size: 36px;
        line-height: 1.2
    }

    .banner-inner-content h4 {
        font-size: 20px
    }

    .row.about-steps.mt-5 .col-md-4 {
        width: 33%
    }

    .about-us-description .col-md-5 {
        display: none
    }

    .pt-5.about-us-content {
        padding-right: 0
    }

    .pt-5.about-us-content p {
        text-align: center !important
    }

    .col-md-6.about-bottom-R {
        text-align: center;
        margin-top: 20px
    }

    .about-steps-box figure img {
        width: 49px
    }

    .inner-banner img {
        height: 140px
    }

    .dash-R {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px
    }

    .sidebar-L {
        top: 0;
        position: static;
        height: auto;
        padding-bottom: 0;
        width: 100%;
        padding-top: 0
    }

    .versioncode {
        bottom: auto;
        z-index: 999;
        color: #000;
        left: auto;
        font-size: 13px;
        left: 15px;
        top: -3px;
        background: transparent;
        width: auto;
        padding: 0;
        height: auto;
    }

    .versioncode:hover {
        color: #000;
    }


    .sidebar-L ul {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: auto;
    }

    .sidebar-L li a {
        flex-direction: column;
        text-align: center;
        padding: 10px 15px
    }

    .sidebar-L ul li {
        margin-bottom: 0;
        flex: 1
    }

    .sidebar-L li a i {
        padding-right: 0;
        font-size: 24px
    }

    .padding-head {
        padding-top: 58px
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px
    }

    .btn-group-box {
        display: flex
    }

    .profile-title-text img {
        margin-right: 5px
    }

    .form-head h2 {
        font-size: 22px
    }

    .form-box.row.mb-5.pb-5 {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important
    }

    .form-head {
        margin-bottom: 20px
    }

    .sub-title-inner {
        padding: 0 40px;
        margin-bottom: 20px;
        text-align: center
    }

    .patterns-banner.inner-space {
        padding-top: 10px
    }

    .row.dashboard-box-main .col-md-6.mb-3 {
        width: 50%
    }

    .food-info span {
        padding-right: 0
    }

    .select-filter {
        width: 100%
    }

    .inner-banner {
        margin-top: -10px
    }

    .faq-inner .nav-tabs .nav-link {
        font-size: 18px
    }

    .form-box.row form.col-md-5.m-auto {
        margin-top: 30px !important
    }

    .btn-group-box .btn {
        width: auto;
        padding: 1px 25px;
        height: auto;
        font-size: 13px
    }
    .step-button.btn {
        margin-bottom: 13px;
        font-size: 14px;
        height: auto
    }

    .terms-policy-content-box h4 {
        font-size: 18px
    }

    .terms-policy-content-box p {
        font-size: 14px
    }

    .dish-review-box-L h4 {
        font-size: 18px
    }

    .form-control,
    select.form-control {
        height: 40px
    }

    .upload-document .uploding-list li {
        width: 85px;
    }
    .upload-document .uploding-list li img{max-height: 85px; width: auto;}


    /* .upload-document .uploding-list li:last-child {
        width: 100%
    } */

    .welcome-box.text-center {
        margin-bottom: 50px !important
    }

    .profile-title-text img {

        width: 40px;
        height: 40px;

    }

span.profile-title-text figure {
    flex: 0 0 45px;
}

.dish-name-content figcaption h3 {
    font-size: 13px;
    padding-left: 15px;
}

.btn-group-add .btn.purple-btn {
    width: 178px;
    height: 50px;
    font-size: 14px;
    padding: 0.375rem 0.75rem;
}

.form-box .form-head{padding-left:45px; padding-right: 45px;}

.common-modal .close-btn{right:7px; top:5px;}
.yes-no-btn{display: flex; justify-content: center;}


ul.banner-contact-list li figure{margin-right:15px;}
ul.banner-contact-list{flex-wrap: wrap;}
ul.banner-contact-list li{margin-right: 0px; margin-bottom: 15px;}
.intro-content-head h2, .catering-content-box h2{font-size: 18px;}
.contact-info-content h2{font-size: 18px;}
.catering-content-box ul li{font-size: 14px;}

 .testimonaials-slider .item {
     min-height: 220px;
 }
}



@media (max-width: 575px) {
    .banner-top-L h1 {
        font-size: 29px;
        line-height: 1.2
    }

    .banner-top-L small {
        font-size: 20px
    }

    .banner-main-box {
        height: auto
    }

    .banner-top-R {
        width: 22%
    }

    .shape-1 {
        display: none
    }

    .inner-tabs-link li a {
        padding: 0 25px
    }

    .inner-tabs-link li a {
        font-size: 15px
    }

    .similer-title-head h2 {
        font-size: 22px
    }

    .food-box-similer figure img {
        height: 164px
    }

    .footer-menu {
        padding: 35px 0 5px 0
    }

    .food-box-similer figcaption h3 {
        font-size: 15px;
        line-height: 22px
    }

    .row.join-customer .col-md-3 {
        margin-bottom: 25px
    }

    .food-box-similer figcaption p {
        font-size: 14px;
        line-height: 21px
    }

    .wrapper-video iframe {
        height: 250px !important
    }

    .wrapper-video img {
        height: 250px
    }

    input.form-control {
        height: 40px
    }

    .textarea-box textarea {
        height: 120px
    }

    .btn {
        font-size: 16px
    }

    .shape-2 img {
        width: 90px
    }

    .inner-tabs-link li span {
        margin-right: 8px
    }

    .shape-4 img {
        width: 70px;
    }






    .dish-name-content figure img {
        width: 50px;
        height: 50px
    }

    .min-height-box {
        min-height: 50px
    }

    .all-dish-item {
        margin-bottom: 15px
    }

    .dash-head h3 {
        font-size: 22px
    }

    .breadcrumb {
        margin-bottom: 0
    }

    .inner-title {
        font-size: 16px
    }

    .sidebar-L li a {
        font-size: 14px
    }

    .btn-group-box {
        margin-top: 0 !important
    }

    .inner-header-L figure img {
        width: 125px
    }

    .combine-group .mb-3 {
        width: 100%
    }

    select.form-control {
        height: 40px !important
    }

    .dietary-list li {
        margin-right: 18px;
        width: 33%
    }

    .action-btn a {
        width: 40px;
        height: 40px;
        font-size: 16px;
        margin-left: 5px
    }

    .contact-info-list li {
        flex: 0 0 45%;
        margin: 0 10px
    }

    .contact-info-list figcaption h4 {
        padding-bottom: 8px;
        font-size: 18px
    }

    .Dishes-list li {
        margin-right: 20px
    }

    .Delivery-content {
        margin-bottom: 20px
    }

    .faq-inner .nav-tabs .nav-link {
        font-size: 16px
    }

    .filter-button {
        margin-right: 5px;
        font-size: 14px;
        margin-bottom: 5px;
    }


    .filter-text-button {
        flex-wrap: wrap;
    }

    .filter-text-button-R {
        margin-left: 0;
        margin-top: 12px;
        margin-bottom: 20px;
    }

    .col-md-3.filter {
        width: 100%;
    }

    .gallery_product-top img {
        height: auto;
    }

    .dish-review-box-L h4 {
        font-size: 16px;
    }

    .dish-review-box-L figure {
        width: 50px;
        height: 50px;
    }

    .tags-input-wrapper {
        padding: 2px 5px;
    }

    .tags-input-wrapper .tag {
        font-size: 12px;
    }

    .radio-coustom-design p {
        font-size: 13px;
    }

    .settings-Left {
        width: 100%;
    }


    .setting-right {
        width: 100%;
    }

    .notifications-box ul li {
        flex-direction: row;
        margin-bottom: 10px;
    }

    .notifications-inner {
        width: 60px;
    }

    .notifications-inner {
        width: 85px;
    }

    .notifications-box-head .d-flex {
        width: 100%;
        justify-content: flex-end;
    }

    .setting-right .nav-tabs .nav-link {
        font-size: 14px;
        padding: 10px 10px !important;
    }

    .uploding-list li {
        width: 20%;
    }

    .inner-heading h2 {
        font-size: 20px;
    }

    .space-2 .modal-content {
        padding: 10px 30px;
    }

    .slider-box-content .carousel-item img {
        height: auto;
    }

    .modal-content {
        padding: 48px 30px;
    }

    .col-md-6.about-bottom-R h6 {
        font-size: 22px !IMPORTANT;
        line-height: 30px;
    }

    .dish-name-content figure {
        max-width: 40px;
    }

    .dish-name-content figure img {
        width: 40px;
        height: 40px;
    }


    .banner-top-L h1 strong {
        font-size: 22px;
        line-height: 22px;
    }

    p.banner-top-L-content p p {
        font-size: 14px;
        line-height: 22px;
    }

    .banner-top-L h1 strong {
        font-size: 22px;
        line-height: 29px;
    }

    .google-btn a img {
        width: 105px;
    }

    .google-btn.mt-4 {
        width: 60%;
    }

    .banner-top-L {
        padding-right: 40px;
    }

    .banner-top {
        position: relative;
        padding: 35px 0;
    }

    .row.mt-4.delivery-main-head .col-md-3:nth-child(2),
    .row.mt-4.delivery-main-head .col-md-3:nth-child(3) {
        width: 50%;
    }

    

    span.profile-title-text small {
        font-size: 12px;
    }

    .dish-action-box .all-dish-item{width: 33% !important;}
    .delivery-main-head .my-menu-content .tooltip{left: auto; right: 10px;}
    .delivery-main-head .my-menu-content .tooltip:after{left: auto; right: 5px;}
    
    .css-26l3qy-menu > div{height: 100px; overflow: auto;}

    .three-combine-btn .btn{width: 100% !important; margin-right: 0 !important; padding: 4px 26px;}
    .print-info-table li h3{padding: 10px; font-size: 14px;}
    .print-info-table li span{padding: 6px 10px; font-size: 14px;}
    .text-info-print{font-size: 14px;}
    .print-info-table{width: 100%;}
    .print-info-table{margin-top: 10px;}
     
    .avatar-upload .tooltip{left: auto; right: 0;}
    .avatar-upload .tooltip:after{left: auto; right: 10px;}

    body .css-eziifo{margin-top: 50px;}


.Dishes-list-L{max-width: 56px; flex:0 0 56px;}


}


@media (max-width: 480px) {
    .inner-tabs-link li a {
        padding: 0 10px
    }

    .inner-tabs-link li a {
        font-size: 14px
    }

    .inner-tabs-link li span {
        width: 38px;
        height: 38px
    }

    .inner-tabs-link li a img {
        width: 18px
    }

    .banner-top-L small {
        font-size: 18px
    }

    .banner-top-L h1 {
        font-size: 26px
    }

    .google-btn a img {
        width: 120px
    }

    .banner-top-R {
        width: 27%
    }

    .inner-tabs-link {
        padding-bottom: 25px;
        padding-top: 38px !important
    }

    .row.join-customer .col-md-3 {
        width: 100%
    }

    .food-box-similer figure img {
        height: 220px
    }

    .food-box-similer figcaption h3 {
        font-size: 18px
    }

    .join-customer .text-center.mt-5 {
        margin-top: 0 !important
    }

    .shape-3 {
        display: none
    }

    .footer-content h3 {
        font-size: 14px
    }

    .footer-top-left figure img {
        width: 125px
    }

    .btn {
        width: 160px;
        height: 45px;
        line-height: 35px
    }

    .footer-social li a {
        width: 38px;
        height: 38px
    }

    .navbar-toggler-icon {
        height: 35px;
        width: 40px
    }

    span.navbar-toggler-icon i {
        font-size: 25px
    }

    .sub-title {
        font-size: 30px
    }

    .row.about-steps.mt-5 .col-md-4 {
        width: 100%
    }

    .about-steps-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%
    }

    .about-steps-box figure {
        height: 100px;
        width: 120px;
        padding-left: 10px;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .about-steps-box figcaption {
        height: auto;
        flex: 1;
        height: 100px
    }

    .about-steps-box figcaption h4 br {
        display: none
    }

    .about-steps-box figcaption {
        justify-content: flex-start;
        padding-left: 12px
    }

    .row.about-steps.mt-5 .col-md-4 {
        width: 100%;
        margin-bottom: 12px
    }

    .about-steps-box:after {
        height: 80%;
        width: 90%
    }


    .btn {
        font-size: 14px
    }

    .info-list {
        flex-direction: column
    }

    .info-list li a {
        padding-right: 0;
        margin-right: 0
    }

    .info-list {
        padding: 5px 8px
    }

    .profile-title-text {
        font-size: 12px
    }

    .dietary-list li {
        margin-right: 18px;
        width: 44%
    }

    .form-head h2 {
        font-size: 18px
    }

    .contact-info-list li {
        flex: 0 0 100%;
        margin: 0;
        width: 100%;
        margin-bottom: 20px
    }

    .contact-info-list {
        width: 100%;
        flex-direction: column
    }

    .btn {
        width: 100%
    }

    .row.dashboard-box-main .col-md-6.mb-3 {
        width: 100%
    }

    .row.dashboard-box-main .col-md-4 {
        width: 100%;
        margin-bottom: 15px
    }

    .Dishes-list {
        flex-wrap: wrap
    }

    .Dishes-list li {
        margin-right: 0;
        margin-bottom: 18px
    }

    .faq-inner .nav-tabs {
        border-bottom: 0;
        padding-top: 0 !important;
    }

    .form-box.row form.col-md-5.m-auto {
        width: 90%;

    }

    .filter-button {
        margin-right: 12px;
        font-size: 14px;
        margin-bottom: 12px;
    }

    .Delivery-content li {
        padding-right: 0;
    }

    .my-order-food-info.food-info.food-info-break span {
        width: 100%;
    }

    .dish-review-box {
        flex-wrap: wrap;
    }

    .dish-review-box-L {
        width: 100%;
        margin-bottom: 10px;
    }


    .switch {
        margin: 0 10px;
    }

    .notifications-inner {
        width: 70px;
    }

    .forget-passowrd {
        font-size: 13px;

    }

    .space-2 .modal-content {
        padding: 10px 15px;
    }

    .modal-body .btn.purple-btn {
        width: 100%;
    }
    .yes-no-btn .btn{min-width: 85px;}


    .terms-policy-content-box ol li {
        font-size: 14px;
    }

    .google-btn.mt-4 {
        width: 95%;
    }

    .banner-top-R {
        display: none;
    }

    .banner-top-L {
        padding-right: 0;
    }

    .banner-top-L .banner-top-L-content {
        padding-right: 0;
    }

    .info-list li a {
        border: 0;
    }

    .tooltip {
        min-width: 195px;
    }

    .pagination-box .pagination-main {
        width: 100% !important;
    }

    .tooltip p {
        max-height: 70px;
    }

    .add-dish .all-dish-item {
        flex: 0 0 auto;
        width: 50%;
    }

    .all-dish-item .d-flex.align-items-center.mt-4 {
        margin-top: 0px !important;
    }

    a.btn.purple-btn {
        margin-bottom: 12px;
    }

    .mt-5.btn-group-box {
        flex-wrap: wrap !important;
    }

    .purple-btn {
        width: 100% !important;
        margin-bottom: 10px !important;
    }
    
    .form-box .purple-btn{margin-bottom: 0 !important;} 


    a.btn.purple-btn.me-3 {
        margin-right: 0 !important;
    }
    .btn-group a.btn.purple-btn.me-3{margin-right: 10px !important; min-width:120px;}
    .btn-group a.btn{min-width: 120px;}

    .filter-text-button-R a.btn.purple-btn.min-height.hvr-sweep-to-right.trans-btn {
        margin-left: 0 !important;
    }

    .drop-down-profile .dropdown-menu.show{    right: -47px !important;}
   
    .skip-next-back-bg{flex-wrap: wrap;}
    .skip-next-back-bg .btn{margin: 0 0 10px 0 !important;}
    .skip-next-back-bg .btn:last-child{margin-bottom: 0 !important;}
    body .accordion-body .btn-group-box .btn{width: auto !important; margin-right: 10px !important; min-width:50px;}
    .row.mt-4.delivery-main-head .col-md-3{width: 100%;}
    .row.mt-4.delivery-main-head .col-md-3:nth-child(2), .row.mt-4.delivery-main-head .col-md-3:nth-child(3){width: 100%;} 
    
    .btn-group-add{flex-wrap: wrap;}
    .btn-group-add .btn {width: 100%; margin-left: 0; margin-right: 0 !important;}
    .inner-title{font-size: 12px;}
    .dish-name-content figure{max-width: 30px;}
    .dish-name-content figure img{width: 30px; height: 30px;}
    .dish-name-content figcaption h3{font-size: 12px; padding-left: 8px;}
    .dish-list{width: 100%; overflow: auto;} 
    .dish-list-inner{width: 400px;} 
    
              



}
.update_button button{
    width: 194px;
    height: 36px;
}